import Utils from "../utils/utils";
import Bridge, { BridgeMessage } from "./bridge";
import { BridgeMessageTypeShare } from "./bridge.types";

export default class BridgeShare extends Bridge {
  constructor() {
    super();
  }
    
  public async share( data:ShareData ): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeShare,
        data: data,
      };
      this.send(message, resolve, reject);
    });
  }
}