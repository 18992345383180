import ImmersalBase from "./immersal.base";
import { CameraIntrinsics, LocalizeResult } from "./immersal.common";
import { Matrix, Quaternion, Tools, Vector3 } from "babylonjs"; // eventually remove dependency on BabylonJS

declare const Module: any;
declare function icvPosePut(arg1: number, arg2: Array<number>, arg3: Array<number>): number;
declare function icvPoseGet(arg1: number, arg2: number, arg3: number): number;
declare function icvReset(): void;
declare function icvFocalLenPut(arg1: number): number;
declare function icvFocalLenGet(): number;
declare function icvFocalLenEstimateCount(): number;

export default class ImmersalWebDevice extends ImmersalBase {
  
  private locWorker!: Worker;
  
  constructor(token: string, mapIds: number[]) {
    super(token, mapIds);
  }

  public override async init(): Promise<void> {
    this.locWorker = await new Promise<Worker>(res => {
      const locWorker = new Worker("js/locworker.js");
      locWorker.addEventListener('message', (ev) => {
        if (ev.data.type === 'Init') {
          res(locWorker);
        }
      })
    });
  }

  public override async loadMap(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('immersal.web.device.loadMap: network response was not ok');
      }      
      const arrayBuffer = await response.arrayBuffer();
      const mapData = new Uint8Array(arrayBuffer);
      await new Promise<void>((resolve, reject) => {
        const loadMapHandler = (e: MessageEvent) => {
          const { type, data } = e.data;
          if (type == "LoadMap") {
            this.locWorker.removeEventListener("message", loadMapHandler);
            const success = ( data >= 0 );
            if( success ) {
              resolve();
            } else {
              reject('immersal.web.device.loadMap: locWorker failed to load map');
            }
          }
        }
        this.locWorker.postMessage({type: "LoadMap", data: mapData});
        this.locWorker.addEventListener("message", loadMapHandler);
      });
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`${error.message || error}`);
      } else {
        throw new Error(String(error));
      }
    }
  }

  public override async localize(imageData?: Uint8ClampedArray, imageWidth?: number, imageHeight?: number, intrinsics?: CameraIntrinsics): Promise<LocalizeResult> {
    return new Promise<LocalizeResult>((resolve, reject) => {
      if (!this.locWorker) {
        reject(new Error('immersal.web.device.localize: LocWorker has not been loaded yet.'))
        return;
      }
      const SOLVER_TYPE = 0;
      const gyro = {x: 0, y: 0, z: 0, w: 1};
      // const quat = Quaternion.FromEulerAngles( Tools.ToRadians(0), Tools.ToRadians(0), Tools.ToRadians(180) );
      // const camRot = {x: quat.x, y: quat.y, z: quat.z, w: quat.w};
      const camRot = {x: 0, y: 0, z: 0, w: 1};
      const intr = {
        fx: intrinsics!.focalLength.x, 
        fy: intrinsics!.focalLength.y, 
        ox: intrinsics!.principalOffset.x, 
        oy: intrinsics!.principalOffset.y
      };
      const time = 0; // not being used.

      const localizeHandler = (e: MessageEvent) => {
        const { type, data } = e.data;
        if (type == "Localize") {
          this.locWorker.removeEventListener("message", localizeHandler );
          const locResult: LocalizeResult = {
            success: false,
            confidence: -1,
          };
          const {r, pos, rot, time, gyro, focalLength} = data;
          const success = ( r >= 0 );
          if( success ) {
            const rotX180 = Quaternion.FromEulerAngles(Tools.ToRadians(180), Tools.ToRadians(0), Tools.ToRadians(0));
            const immersalPos = new Vector3(pos[0], pos[1], pos[2]);
            const immersalRot = new Quaternion(rot[0], rot[1], rot[2], rot[3]).multiplyInPlace(rotX180);
            const immersalScl = new Vector3(1, 1, 1);
            const matrix = Matrix.Compose(immersalScl, immersalRot, immersalPos);
            //
            locResult.success = true;
            locResult.matrix = new Float32Array(16);
            matrix.toArray(locResult.matrix, 0);
            if( this.mapIds.length > 0 ) {
              locResult.map = this.mapIds[0]; // only one map when localizing on device.
            }
          }
          resolve( locResult );
        }
      }
      this.locWorker.postMessage({type: "Localize", data: [imageWidth, imageHeight, intr, imageData, time, gyro, SOLVER_TYPE, camRot]});
      this.locWorker.addEventListener("message", localizeHandler);
    });
  }
}