import EyeTexturePath from "@lincolncenter/ui/eye-icon.png";
import FollowTheEyeTexturePath from "@lincolncenter/ui/follow_the_eye.png";

import { IAssetBase, IAssetTexture } from "@/apps/app.content";
import { getCameraViewMatrix } from "../core/content.common";
import ContentLincolnCenterArrow from "./content.lincolncenter.arrow";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import { Calibration } from "./content.lincolncenter.types";
import {
  Angle,
  Camera,
  Color3,
  Engine,
  Matrix,
  Mesh,
  MeshBuilder,
  Quaternion,
  Scene,
  StandardMaterial,
  TransformNode,
  Vector3,
} from "babylonjs";

export default class ContentLincolnCenterCalibration extends ContentLincolnCenterBase {
  private isCalibrating: boolean = false;
  private root: TransformNode | undefined;
  private currentlyTracking: Mesh | undefined;
  private lastCameraRotation: Vector3 = new Vector3(0, 0, 0);
  private cameraPos: Vector3 | undefined;
  private cameraForward: Vector3 | undefined;
  private cameraRotation: Vector3 | undefined;
  private targetAlpha: number = 1;
  private rotationAngle: number = 0;
  private lastTime: number = 0;
  private deltaTime: number = 0;
  private targetScale: number = 1;
  private baseScale: Vector3 = new Vector3(1, 1, 1);
  private followEyeTexture: IAssetTexture | undefined;
  private lookThisWayTexture: IAssetTexture | undefined;

  private arrow: ContentLincolnCenterArrow | undefined;

  constructor() {
    super();
    this.id = Calibration;
  }

  private setEnabledForHierarchy(node: TransformNode, enabled: boolean) {
    node.getChildMeshes().forEach((mesh) => {
      mesh.isVisible = enabled;
    });
  }

  public setArrowUI (arrow: ContentLincolnCenterArrow, defaultTexture: IAssetTexture) {
    this.arrow = arrow;
    this.lookThisWayTexture = defaultTexture;
  }

  public get assets(): IAssetBase[] {
    return [
      {
        type: "texture",
        id: "eye-texture",
        path: EyeTexturePath,
      },
      {
        type: "texture",
        id: "follow-eye-texture",
        path: FollowTheEyeTexturePath
      }
    ];
  }

  public loadAssetComplete(_asset: IAssetBase): void {
    if (_asset.id === "eye-texture") {
      const _texture = _asset as IAssetTexture;
      const texture = _texture.texture;

      if (texture) {
        texture.hasAlpha = true;
        const mat = this.currentlyTracking?.material as StandardMaterial;
        mat.diffuseTexture = texture;
        mat.emissiveColor = new Color3(1, 1, 1);
        mat.diffuseTexture = texture;
        mat.emissiveTexture = texture;
        mat.diffuseTexture.hasAlpha = true;
        mat.useAlphaFromDiffuseTexture = true;
        mat.needAlphaBlending = () => true;
        mat.alpha = 0;

        const aspectRatio = texture.getSize().width / texture.getSize().height;
        this.currentlyTracking!.scaling = new Vector3(aspectRatio, 1, 1);
        this.baseScale = new Vector3(aspectRatio, 1, 1);
      }
    }
    else if (_asset.id === "follow-eye-texture") {
      const _texture = _asset as IAssetTexture;
      const texture = _texture.texture;
      this.followEyeTexture = _texture;

      if (texture) {
        texture.hasAlpha = true;
      }
    }
  }

  public init(_engine: Engine, _scene: Scene, _camera: Camera): void {
    super.init(_engine, _scene, _camera);

    this.root = new TransformNode("calibrationRoot", this.scene);
    // create a plane to track
    const plane = MeshBuilder.CreatePlane(
      "trackingPlane",
      { size: 1 },
      this.scene
    );
    const material = new StandardMaterial("trackingPlaneMaterial", this.scene);
    material.diffuseColor = new Color3(1.0, 0.0, 0.0);
    material.backFaceCulling = false;
    material.alpha = 0;
    material.needAlphaBlending = () => true;
    
    plane.material = material;

    this.currentlyTracking = plane;
    this.currentlyTracking.parent = this.root;

    // set plane position to be in front of the camera
    this.currentlyTracking.position = new Vector3(0, 0, -3);
    this.updateTransforms();
    this.lastCameraRotation = this.cameraRotation ?? new Vector3(0, 0, 0);

    this.setEnabledForHierarchy(this.root, false);
    this.targetAlpha = 0.0;
  }


  private lerp(a: number, b: number, t: number) {
    return a + (b - a) * t;
  }

  private lerpVector3(a: Vector3, b: Vector3, t: number) {
    return new Vector3(
      this.lerp(a.x, b.x, t),
      this.lerp(a.y, b.y, t),
      this.lerp(a.z, b.z, t)
    );
  }

  private updateVisuals() {
    if (this.root) {
      this.root!.rotation = new Vector3(
        0,
        this.lastCameraRotation!.y -
        Angle.FromDegrees(this.rotationAngle).radians(),
        0
      );
    }

    if (this.arrow) {
      this.arrow.pointTo(this.currentlyTracking!.getAbsolutePosition());
    }
  }

  public setCameraMatrix(matrix: Matrix): void {
    if (matrix) {
      this.cameraForward = Vector3.TransformNormal(
        new Vector3(0, 0, -1),
        matrix
      ).normalize();

      const scale = new Vector3();
      const rotation = new Quaternion();
      const position = new Vector3();

      // Decompose the matrix
      matrix.decompose(scale, rotation, position);

      // Convert the quaternion to Euler angles
      this.cameraRotation = rotation.toEulerAngles();
      this.cameraPos = position;
    }
  }

  //todo: pulsate & vibrate every second of being locked in
  //todo: disable the arrow after it is done (force show = false;;;)

  public startCalibration(): void {
    if (this.isCalibrating) {
      return;
    }

    if (this.camera) {
      if (this.currentlyTracking?.material)
        this.currentlyTracking!.material!.alpha = 0;

      this.setEnabledForHierarchy(this.root!, true);
      this.updateTransforms();
      this.lastCameraRotation = this.cameraRotation ?? new Vector3(0, 0, 0);
      this.isCalibrating = true;
      this.rotationAngle = -30;
      this.targetAlpha = 0.5;
      this.targetScale = 1.0;
    }

    if (this.arrow && this.followEyeTexture && this.followEyeTexture.texture) {
      this.arrow.arrowShowOverride = true;
      this.arrow.setArrowTextTexture(this.followEyeTexture.texture);
    }
  }

  public stopCalibration(): void {
    this.isCalibrating = false;
    this.targetAlpha = 0.0;
    this.targetScale = 0.0;
    if (this.arrow) {
      this.arrow.hideArrow();      
      if (this.lookThisWayTexture && this.lookThisWayTexture.texture)
        this.arrow.setArrowTextTexture(this.lookThisWayTexture!.texture!);
    }
    setTimeout(() => {
      if (this.isCalibrating === false) {
        this.setEnabledForHierarchy(this.root!, false);
      }
    }, 2000);
  }

  private updateTransforms() {
    if (this.scene) {
      const activeCamera = this.scene.activeCamera!;

      const worldMatrix = getCameraViewMatrix(activeCamera).invert();
      const cameraForward = activeCamera.getForwardRay(
        100,
        worldMatrix
      ).direction;

      // Variables to hold scale, rotation, and position
      const scale = new Vector3();
      const rotation = new Quaternion();
      const position = new Vector3();

      // Decompose the matrix into scale, rotation, and position
      worldMatrix.decompose(scale, rotation, position);
      this.cameraPos = position;
      this.cameraForward = cameraForward;
      this.cameraRotation = rotation.toEulerAngles();
    }
  }

  private lockedInTimer: number = 0;

  public render(): void {

    this.updateTransforms();

    this.deltaTime = ( performance.now() - this.lastTime) / 1000;
    this.lastTime = performance.now();

    if (this.cameraPos && this.cameraForward && this.cameraRotation) {
      if (this.isCalibrating) {

        const directionToPlane = this.currentlyTracking!.getAbsolutePosition().subtract(this.cameraPos).normalize();

        const ang = Angle.BetweenTwoVectors(this.cameraForward, directionToPlane);
        const fov = this.scene?.activeCamera!.fov ?? 0.8;

        if (ang.radians() < fov + 0.1) {

          let speedPerc = ang.radians() / (fov + 0.1);

          speedPerc = Math.min(1, speedPerc);
          speedPerc = Math.max(0.3, speedPerc);

          // invert the speed
          speedPerc = 1 - speedPerc;

          this.rotationAngle += 10 * this.deltaTime * speedPerc;

          // check if 
          if (ang.degrees() < 10) {
            this.targetAlpha = 1.0;
            this.targetScale = 1.3;
            this.lockedInTimer += this.deltaTime;
            if (this.lockedInTimer > 1) {

              // vibration
              if (navigator.vibrate) {
                navigator.vibrate([200,200]);
                console.log("vibrating");
              }

              this.lockedInTimer = 0;
            }
          }
          else {
            this.targetAlpha = 0.5;
            this.targetScale = 1.0;
            this.lockedInTimer = 1;
          }

        }
  

        this.root?.position.set(
          this.cameraPos!.x,
          this.cameraPos!.y,
          this.cameraPos!.z
        );
        
        this.updateVisuals();

      }

      // Do this outside of the calibration check
      if (this.currentlyTracking?.material) {
        // lerp towards target alpha with delta time
        this.currentlyTracking.material.alpha = this.lerp(this.currentlyTracking.material.alpha, this.targetAlpha, 5 * this.deltaTime);

        if (this.currentlyTracking) {
          const lerpedScale = this.lerpVector3(this.currentlyTracking.scaling, this.baseScale.scale(this.targetScale), 5 * this.deltaTime);
          this.currentlyTracking!.scaling = lerpedScale;
        }
      }
    }
  }
}
