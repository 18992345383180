import { Button } from "../Elements/Button";
import { useOnClickOutside } from "@/ui/hooks/useOnClickOutside";
import emitter from "@/core/events/eventemitter";
import { useEffect, useRef, useState } from "preact/hooks";
import clsx from "clsx";

const contentItems = [
  { id: "immersal_sparse_114026" },
  { id: "immersal_sparse_114028" },
  { id: "immersal_sparse_114031" },
  { id: "building_mesh" },
  { id: "ballet" },
  { id: "catdance" },
  { id: "taphappenings" },
  { id: "govisland" },
  { id: "annabelle" },
  { id: "idrani" },
  { id: "rooftop" },
  { id: "breakers" },
];

const DebugMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [config, setConfig] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setConfig(params.get("config"));
  }, []);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  const handleContentVisibilityToggle = (
    contentId: string,
    triggeredExternally = false
  ) => {
    setCheckedItems((prevState) => {
      const isChecked = !prevState[contentId];
      const newCheckedItems = { ...prevState };
      if (!contentId.startsWith("immersal") && contentId !== "building_mesh") {
        Object.keys(newCheckedItems).forEach((key) => {
          if (!key.startsWith("immersal") && key !== "building_mesh") {
            newCheckedItems[key] = false;
          }
        });
      }

      newCheckedItems[contentId] = triggeredExternally
        ? !prevState[contentId]
        : isChecked;

      if (!triggeredExternally) {
        emitter.emit(isChecked ? "content-play" : "content-unload", contentId);
      }

      return newCheckedItems;
    });
  };

  useEffect(() => {
    const handleContentPlay = (contentId: string) => {
      setCheckedItems((prevState) => {
        const newCheckedItems = { ...prevState };

        if (
          !contentId.startsWith("immersal") &&
          contentId !== "building_mesh"
        ) {
          Object.keys(newCheckedItems).forEach((key) => {
            if (!key.startsWith("immersal") && key !== "building_mesh") {
              newCheckedItems[key] = false;
            }
          });
        }

        newCheckedItems[contentId] = true;
        return newCheckedItems;
      });
    };

    const handleContentUnload = (contentId: string) => {
      setCheckedItems((prevState) => ({
        ...prevState,
        [contentId]: false,
      }));
    };

    emitter.on("content-loading", handleContentPlay);
    emitter.on("content-unload", handleContentUnload);

    return () => {
      emitter.off("content-loading", handleContentPlay);
      emitter.off("content-unload", handleContentUnload);
    };
  }, []);

  useEffect(() => {
    const handleLoaded = () => {
      const contentIds = contentItems.map(({ id }) => ({
        id,
      }));
      setCheckedItems(
        contentIds.reduce((acc, item) => {
          if (item.id.startsWith("immersal") || item.id === "building_mesh") {
            acc[item.id as string] = true;
          } else {
            acc[item.id as string] = false;
          }
          return acc;
        }, {} as { [key: string]: boolean })
      );
    };
    emitter.on("xr-started", handleLoaded);

    return () => {
      emitter.off("xr-started", handleLoaded);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative z-50">
      <Button onClick={toggleDropdown} className="xw-full h-[50px] bg-black">
        Debug &#x25BC;
      </Button>
      {isOpen && (
        <ul className="absolute bottom-full xmb-2 text-black rounded-sm cursor-pointer bg-white border border-gray-900">
          {contentItems
            .filter((item) => {
              if (
                (item.id.startsWith("immersal") && config !== "onsite") ||
                ((item.id === "building_mesh") && config !== "onsite")
              ) {
                return false;
              }
              return true;
            })
            .map((item, index) => (
              <li
                key={item.id}
                className={clsx(
                  "px-2 py-3 rounded-sm cursor-pointer hover:bg-opacity-90 flex items-center",
                  {
                    "border-b-2 border-gray-900": item.id === "building_mesh",
                  }
                )}
                onClick={() => handleContentVisibilityToggle(item.id)}
              >
                <input
                  type="checkbox"
                  checked={!!checkedItems[item.id!]}
                  onClick={(e) => e.stopPropagation()}
                  onChange={() => handleContentVisibilityToggle(item.id)}
                  className="mr-2 pointer-events-auto"
                />
                {item.id.startsWith("immersal") && `Point Cloud ${index + 1}`}
                {item.id === "building_mesh" && "Wireframe"}
                {!item.id.startsWith("immersal") &&
                  item.id !== "building_mesh" &&
                  item.id}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
export default DebugMenu;
