import Utils from "../../core/utils/utils";
import { Vector3 } from "babylonjs";

//----------------------------------------------------------------
export interface IContentSpotConfig {
  uuid?: string;      // unique identifier.
  name: string;       // name of spot.
  position: Vector3;  // position of spot on the ground.
  radius?: number;    // radius of spot on the ground.
  target?: Vector3;   // spot might want to look at a different target position.
  loaderPosition?: Vector3;
  loaderRotation?: Vector3;
  loaderScale?: Vector3;
}

export class ContentSpotConfig {
  public uuid: string = Utils.UUID();
  public name: string = "";
  public position: Vector3 = new Vector3(0, 0, 0);
  public radius: number = 1;
  public target: Vector3 = new Vector3(0, 0, 0);
  public loaderPosition?: Vector3;
  public loaderRotation?: Vector3;
  public loaderScale?: Vector3;

  constructor(data: IContentSpotConfig) {
    this.name = data.name;
    if( data.position ) {
      this.position.copyFrom(data.position);
    }
    if( data.target ) {
      this.target.copyFrom(data.target);
    } else {
      this.target.copyFrom(data.position);
    }
    if( data.radius ) {
      this.radius = data.radius;
    }
    if( data.loaderPosition ) {
      this.loaderPosition = data.loaderPosition;
    }
    if( data.loaderRotation ) {
      this.loaderRotation = data.loaderRotation;
    }
    if( data.loaderScale ) {
      this.loaderScale = data.loaderScale;
    }
  }
}

export class ContentSpot {
  public config: ContentSpotConfig;
  public on: boolean = false;

  constructor( config: ContentSpotConfig ) {
    this.config = config;
  }

  public contentOn() {
    this.on = true;
  }

  public contentOff() {
    this.on = false;
  }

  public render() {
    // override in subclass.
  }
}