import ModelPath from '@lincolncenter/annabelle/annabelle-model.glb';
import VideoPath from '@lincolncenter/annabelle/annabelle-video.mp4';
//
import ContentLincolnCenterModelVideo from './content.lincolncenter.modelvideo';
import { Annabelle } from './content.lincolncenter.types';

export default class ContentLincolnCenterAnnabelle extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = Annabelle;
    this.modelPath = ModelPath;
    this.videoPath = VideoPath;
    this.videoMaterialName = 'AnnabelleCOMP';
  }
}