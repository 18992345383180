import { Matrix, Quaternion, Vector3 } from "babylonjs";
import PoseProcess from "./PoseProcess";

export default class PoseProcessFilter extends PoseProcess {

  private mHistorySize = 8;
  private mP = new Array(this.mHistorySize);
  private mX = new Array(this.mHistorySize);
  private mZ = new Array(this.mHistorySize);
  private mSamples = 0;

  private x = new Vector3(0, 0, 0);
  private z = new Vector3(0, 0, 0);
  private up = new Vector3(0, 0, 0);
  private mean = new Vector3(0, 0, 0);
  private avg = new Vector3(0, 0, 0);

  constructor() {
    super();
    for( let i=0; i<this.mHistorySize; i++ ) {
      this.mP[i] = new Vector3(0, 0, 0);
      this.mX[i] = new Vector3(0, 0, 0);
      this.mZ[i] = new Vector3(0, 0, 0);
    }
  }

  public setData(matrix:Matrix) {
    const idx = this.mSamples % this.mHistorySize;
    this.matrixColumnToVector(matrix, 3, this.mP[idx]);
    this.matrixColumnToVector(matrix, 0, this.mX[idx]);
    this.matrixColumnToVector(matrix, 2, this.mZ[idx]);
    this.mSamples++;
    const n = this.mSamples > this.mHistorySize ? this.mHistorySize : this.mSamples;
    this.position.copyFrom( this.filterAVT(this.mP, n) );
    this.x.copyFrom( this.filterAVT(this.mX, n) ).normalize();
    this.z.copyFrom( this.filterAVT(this.mZ, n) ).normalize();
    Vector3.CrossToRef(this.z, this.x, this.up);
    this.up.normalize();
    Quaternion.FromLookDirectionRHToRef(this.z, this.up, this.rotation);
  }

  private matrixColumnToVector(matrix:Matrix, index:number, out:Vector3) {
    out.set(matrix.m[index*4], matrix.m[index*4+1], matrix.m[index*4+2]);
  }

  private filterAVT(buf:Array<Vector3>, n:number) {
    this.mean.set(0, 0, 0);
    for (let i = 0; i < n; i++) {
      this.mean.addInPlace(buf[i]);
    }
    this.mean.scaleInPlace( 1.0 / n );
    if (n <= 2) {
      return this.mean;
    }
    let s = 0;
    for (let i = 0; i < n; i++) {
      s += Vector3.DistanceSquared(buf[i], this.mean);
    }
    s /= n;
    this.avg.set(0, 0, 0);
    let ib = 0;
    for (let i = 0; i < n; i++) {
      const d = Vector3.DistanceSquared(buf[i], this.mean);
      if (d <= s) {
        this.avg.addInPlace( buf[i] );
        ib++;
      }
    }
    if (ib > 0) {
      this.avg.scaleInPlace( 1.0 / ib )
      return this.avg;
    }
    return this.mean;
  }

  public reset() {
    super.reset();
    this.mSamples = 0;
  }
}