import { Matrix, Quaternion, Vector3 } from "babylonjs";
import PoseProcess from "./PoseProcess";

export default class PoseProcessSmooth extends PoseProcess {

  public mWarpOutsideThreshold = true;
  public mWarpThresholdDistSq = 5.0 * 5.0;
  public mWarpThresholdCosAngle = Math.cos(20.0 * Math.PI / 180.0);

  private positionTarget = new Vector3(0, 0, 0);
  private rotationTarget = Quaternion.Identity();
  private firstData = true;

  constructor() {
    super();
  }

  public setData(matrix:Matrix) {
    this.positionTarget.set(matrix.m[12], matrix.m[13], matrix.m[14]);
    Quaternion.FromRotationMatrixToRef(matrix, this.rotationTarget);
    if( this.firstData ) {
      this.firstData = false;
      this.position.copyFrom(this.positionTarget);
      this.rotation.copyFrom(this.rotationTarget);
    }
  }

  public update( timeDelta: number ) {
    const distSq = Vector3.DistanceSquared(this.position, this.positionTarget);
    const cosAngle = Quaternion.Dot(this.rotation, this.rotationTarget);
    if (this.mWarpOutsideThreshold && (distSq > this.mWarpThresholdDistSq || cosAngle < this.mWarpThresholdCosAngle)) {
      this.position.copyFrom(this.positionTarget);
      this.rotation.copyFrom(this.rotationTarget);
    } else {
      const smoothing = 0.025;
      let steps = timeDelta / (1.0 / 60.0);
      if (steps < 1.0) { 
        steps = 1.0;
      } else if (steps > 6.0) {
        steps = 6.0;
      }
      const alpha = 1.0 - Math.pow(1.0 - smoothing, steps);
      Vector3.LerpToRef(this.position, this.positionTarget, alpha, this.position);
      Quaternion.SlerpToRef(this.rotation, this.rotationTarget, alpha, this.rotation);
    }
  }

  public reset() {
    super.reset();
    this.positionTarget.set(0, 0, 0);
    this.rotationTarget.set(0, 0, 0, 1);
  }
}