import {
  AnimationGroup,
  Mesh,
  PBRMaterial,
  Scene,
  VideoTexture,
} from "babylonjs";
import { IAssetBase, IAssetModel, IAssetVideo } from "../../apps/app.content";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import { applyAlphaVideoToMesh } from "./content.lincolncenter.common";
//----------------------------------------------------------------
export default class ContentLincolnCenterModelVideo extends ContentLincolnCenterBase {
  protected modelPath?: string;
  protected videoPath?: string;
  protected model?: IAssetModel;
  protected video?: IAssetVideo;
  protected videoMaterialName?: string;
  private lastSynchCheck = 0;
  private checkSyncEveryMs = 500;
  protected checkVideoSynchronization = true;
  protected loopAnimation = true;

  constructor() {
    super();
  }

  //--------------------------------------------------------------
  public override get assets(): IAssetBase[] {
    return [
      {
        id: `${this.id}-model`,
        type: "model",
        path: this.modelPath!,
      },
      {
        id: `${this.id}-video`,
        type: "video",
        path: this.videoPath!,
      },
    ];
  }

  //--------------------------------------------------------------
  public override setStateLoading(): void {
    super.setStateLoading();
  }

  public override setStateUnload(): void {
    super.setStateUnload();
    this.model = undefined;
    this.video = undefined;
  }

  //--------------------------------------------------------------
  public override loadAssetComplete(asset: IAssetBase): void {
    if (asset.id === `${this.id}-model`) {
      this.model = asset as IAssetModel;
      for (const mesh of this.model.meshes ?? []) {
        mesh.isVisible = false;
        if (mesh.material && mesh.material instanceof PBRMaterial) {
          const pbrMaterial = mesh.material as PBRMaterial;
          pbrMaterial.unlit = true;
        }
      }
    } else if (asset.id === `${this.id}-video`) {
      this.video = asset as IAssetVideo;
    }
    let loaded = true;
    loaded = loaded && this.model !== undefined;
    loaded = loaded && this.video !== undefined;
    if (loaded) {
      this.setStateLoaded();
    }

    this.bindAlphaVideo();
  }

  protected bindAlphaVideo(): void {
    if (!this.model || !this.video) {
      return;
    }
    for (const abstractMesh of this.model.meshes ?? []) {
      const mesh = abstractMesh as Mesh;
      if (mesh.material && mesh.material.name === this.videoMaterialName) {
        this.applyAlphaVideoToMesh(mesh, this.video.videoTexture!, this.scene!);
      }
    }
  }

  protected applyAlphaVideoToMesh(
    mesh: Mesh,
    videoTexture: VideoTexture,
    scene: Scene
  ): void {
    applyAlphaVideoToMesh(mesh, videoTexture, scene);
  }

  public override setStatePlay(): void {
    super.setStatePlay();
    //
    this.playContent();
  }

  protected playContent(): void {
    for (const mesh of this.model?.meshes ?? []) {
      mesh.isVisible = true;
    }
    const animations = this.model!.animations;
    if (animations && animations.length > 0) {
      animations.map((anim: AnimationGroup) => {
        anim.start(this.loopAnimation, 1.0, anim.from, anim.to, false);
      });
    }
    if( this.video ) {
      if( this.video.videoTexture ) {
        this.video.videoTexture.video.play();
      } else {
        console.error('ContentLincolnCenterModelVideo.playContent: videoTexture is undefined');
      }
    } else {
      console.error('ContentLincolnCenterModelVideo.playContent: video is undefined');
    }
  }

  protected checkSync = () => {
    const videoElement = this.video?.videoTexture?.video;

    if (!videoElement) {
      return;
    }

    const currentTime = videoElement.currentTime;
    const animations = this.model?.animations;

    if (animations && animations.length > 0 && animations[0].isPlaying) {
      animations.forEach((anim: AnimationGroup) => {
        // Calculate expected frame based on video's current time and animation frame rate
        const expectedFrame = Math.floor(
          currentTime * anim.targetedAnimations[0].animation.framePerSecond
        );

        // Retrieve the actual current frame of the animation
        const actualFrame =
          anim.targetedAnimations[0].animation.runtimeAnimations[0]
            .currentFrame;

        // Calculate the time difference between expected and actual frames
        const frameRate = anim.targetedAnimations[0].animation.framePerSecond;

        const timeDifference = Math.abs(
          (expectedFrame - actualFrame) / frameRate
        );

        // 1 ms tolerance for desynchronization
        if (timeDifference > 0.1) {
          console.warn(
            `Desynchronization detected: Animation '${
              anim.name
            }' is out of sync by ${timeDifference.toFixed(2)} seconds.`
          );

          // Set frame
          anim.goToFrame(expectedFrame);
        }
      });
    }
  };

  public override render(): void {
    super.render();

    if (this.isStatePlay && this.checkVideoSynchronization) {
      // Check every x MS when video is playing
      if (this.lastSynchCheck + this.checkSyncEveryMs < Date.now()) {
        this.checkSync();
        this.lastSynchCheck = Date.now();
      }
    }
  }
}
