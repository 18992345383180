/* eslint-disable @typescript-eslint/no-unused-vars */
import { CameraIntrinsics, LocalizeResult } from "./immersal.common";

export default class ImmersalBase {
  protected token: string = '';
  protected mapIds: number[] = [];
  
  constructor(token: string, mapIds: number[]) {
    this.token = token;
    this.mapIds = mapIds;
  }

  public async init(): Promise<void> {
    return Promise.reject(new Error("immersal.base.init must be overridden in the subclass"));
  }

  public async loadMap(_url: string): Promise<void> {
    return Promise.reject(new Error("immersal.base.loadMap must be overridden in the subclass"));
  }

  public async localize(_imageData?: Uint8ClampedArray, _imageWidth?: number, _imageHeight?: number, _intrinsics?: CameraIntrinsics): Promise<LocalizeResult> {
    return Promise.reject(new Error("immersal.base.localize must be overridden in the subclass"));
  }
}