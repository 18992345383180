import ContentLincolnCenterModelVideo from "./content.lincolncenter.modelvideo";

import Video from "@lincolncenter/spot/hotspot.mp4";
import Model from "@lincolncenter/spot/spots-named.glb";
import { IAssetBase } from "@/apps/app.content";
import { ShaderMaterial, TransformNode, Vector3 } from "babylonjs";
import { ContentSpotConfig } from "../core/content.spot";
import { Spots } from "./content.lincolncenter.types";

export default class ContentLincolnCenterSpotMesh extends ContentLincolnCenterModelVideo {
  
  private spots: { [key: string]: ContentSpotConfig } = {};
  private currentThreshold = 1;
  private frameCallbacks: Array<(timestamp: number) => void> = [];
  private hasRun = false;

  constructor() {
    super();
    this.id = Spots;
    this.modelPath = Model;
    this.videoPath = Video;
    this.videoMaterialName = "Material.001";
    this.checkVideoSynchronization = false;
  }

  public loadAssetComplete(asset: IAssetBase): void {
    super.loadAssetComplete(asset);
    this.model?.meshes?.forEach((mesh) => {
      if (mesh.parent && mesh.parent?.name) {
        if (this.spots[mesh.parent?.name] !== undefined) {
          const transformNode = mesh.parent as TransformNode;
          transformNode.position.copyFrom(
            this.spots[mesh.parent?.name].position
          );
          const flooredTarget = new Vector3(
            this.spots[mesh.parent?.name].target.x,
            0,
            this.spots[mesh.parent?.name].target.z
          );
          transformNode.lookAt(flooredTarget);

          transformNode.scaling.copyFrom(
            new Vector3(
              this.spots[mesh.parent?.name].radius * 2,
              this.spots[mesh.parent?.name].radius * 2,
              this.spots[mesh.parent?.name].radius * 2,
            )
          );
        }
      }
    });
  }

  public initSpot(config: ContentSpotConfig): void {
    this.spots[config.name] = config;
  }

  public showHideSpot(name: string, show: boolean): void {
    this.model?.meshes?.forEach((mesh) => {
        if (mesh.parent && mesh.parent?.name) {
            if (mesh.parent?.name === name && mesh.material?.name === "alphaVideoShaderMaterial") {
                const material = mesh.material as ShaderMaterial;

                const initialThreshold = this.currentThreshold; // Default to 0 if not set
                const targetThreshold = show ? 1 : -1;
                const duration = 500; // Duration in milliseconds
                const startTime = performance.now();

                const animate = (currentTime: number) => {
                    const elapsedTime = currentTime - startTime;
                    const progress = Math.min(elapsedTime / duration, 1); // Normalize progress (0 to 1)
                    this.currentThreshold = initialThreshold + progress * (targetThreshold - initialThreshold);
                    material.setFloat("alpha", this.currentThreshold);
                    if (progress < 1) {
                        this.requestFrame(animate);
                    }
                };

                this.requestFrame(animate);
            }
        }
    });
  }

  public override render(): void {
    super.render();

    const currentFrameCallbacks = [...this.frameCallbacks];
    for (const callback of currentFrameCallbacks) {
      callback(performance.now());

      // remove this one from frame callbacks
      const index = this.frameCallbacks.indexOf(callback);
      if (index > -1) {
        this.frameCallbacks.splice(index, 1);
      }
    }
  }

  private requestFrame (callback: (timestamp: number) => void): void {
    this.frameCallbacks.push(callback);
  }
  
  public setStateLoaded(): void {
    super.setStateLoaded();
  }

  public runContent() {
    if (!this.hasRun) {
      this.playContent();
      this.hasRun = true
    }
  }
}
