import { EJApp } from "../utils/utils";
import MediaRecorderApp from "./mediarecorder.app";
import MediaRecorderBase from "./mediarecorder.base";
import { MediaRecorderResponse } from "./mediarecorder.types";
import MediaRecorderWeb from "./mediarecorder.web";

export default class MediaRecorder extends MediaRecorderBase {
  private impl: MediaRecorderBase;
  
  constructor() {
    super();
    if( EJApp ) {
      this.impl = new MediaRecorderApp();
    } else {
      this.impl = new MediaRecorderWeb();
    }
  }

  public async start(): Promise<MediaRecorderResponse> {
    return this.impl.start();
  }

  public async stop(): Promise<MediaRecorderResponse> {
    return this.impl.stop();
  }
}