/* eslint-disable @typescript-eslint/no-unused-vars */

import { AbstractMesh, AnimationGroup, Color4, CubeTexture, IParticleSystem, ISceneLoaderProgressEvent, Mesh, PointsCloudSystem, Scene, SceneLoader, SceneLoaderSuccessCallback, Skeleton, Sound, Texture, Vector3, VideoTexture, VideoTextureSettings } from "babylonjs";
import 'babylonjs-loaders';
import { parse } from "@loaders.gl/core";
import { PLYLoader } from '@loaders.gl/ply';
import Utils from "../core/utils/utils";
import AppBabylon from "./app.babylon";

//----------------------------------------------------------------
export interface IAssetBase {
  type: "model" | "pointcloud" | "texture" | "cubetexture" | "video" | "audio";
  path: string;
  id?: string;
  loaded?: boolean;
  loading?: boolean;
  loadProgress?: number;
  abortCtrl?: AbortController;
}

export interface IAssetPointCloud extends IAssetBase {
  size?: number;
  color?: [number, number, number];
  mesh?: Mesh;
}

export interface IAssetModel extends IAssetBase {
  meshes?: AbstractMesh[];
  meshRoot?: AbstractMesh;
  animations?: AnimationGroup[];
}

export interface IAssetTexture extends IAssetBase {
  texture?: Texture;
}

export interface IAssetCubeTexture extends IAssetBase {
  cubeTexture?: CubeTexture;
}

export interface IAssetVideo extends IAssetBase {
  videoTexture?: VideoTexture;
}

export interface IAssetAudio extends IAssetBase {
  audio?: Sound;
}

//----------------------------------------------------------------
export class AppContentConfig {
  public loadBatchSize?: number;
}

//----------------------------------------------------------------
export default class AppContent extends AppBabylon {

  protected contentConfig: AppContentConfig = new AppContentConfig();
  protected assets: IAssetBase[] = [];
  protected assetsLoading: IAssetBase[] = [];

  constructor(renderCanvas: HTMLCanvasElement) {
    super(renderCanvas);
  }

  public setConfigContent( contentConfig: AppContentConfig ) {
    this.contentConfig = contentConfig;
  }

  //----------------------------------------------------------------
  public async loadContents( assetsToLoad:IAssetBase[] ) {
    // check if any assets are already loading.
    // this is a work around the batching feature,
    // making sure assets can be removed from the async for-loop,
    // and are not loaded after being unloaded.
    for( const assetLoading of this.assetsLoading ) {
      for( const assetToLoad of assetsToLoad ) {
        if( assetLoading.path === assetToLoad.path ) {
          const asssetToLoadIdx = assetsToLoad.indexOf(assetToLoad);
          assetsToLoad.splice(asssetToLoadIdx, 1);
        }
      }
    }
    this.assetsLoading.push(...assetsToLoad);
    // load assets.
    const batchSize = this.contentConfig.loadBatchSize;
    if( batchSize ) {
      while( this.assetsLoading.length > 0 ) {
        const numToLoad = Math.min(batchSize, this.assetsLoading.length);
        const batch = this.assetsLoading.splice(0, numToLoad);
        await this.loadAssets( batch );
      }
    } else {
      const numToLoad = this.assetsLoading.length; // load all assets at once.
      const batch = this.assetsLoading.splice(0, numToLoad);
      await this.loadAssets( batch );
    }
  }

  public unloadContents( assetsToUnload: IAssetBase[] ) {
    // if any assets are waiting to be loaded,
    // remove them form the loading list.
    for( const assetLoading of this.assetsLoading ) {
      for( const assetToUnload of assetsToUnload ) {
        if( assetLoading.path === assetToUnload.path ) {
          const assetLoadingIdx = this.assetsLoading.indexOf(assetLoading);
          this.assetsLoading.splice(assetLoadingIdx, 1);
        }
      }
    }
    this.unloadAssets( assetsToUnload );
  }

  //----------------------------------------------------------------
  protected async loadAssets( assetsToLoad:IAssetBase[] ) {
    const promises = assetsToLoad.map(assetToLoad => this.loadAsset(assetToLoad));
    await Promise.all(promises);
  }

  protected async loadAsset( assetToLoad:IAssetBase ) {
    const assetIdx = this.assets.findIndex((asset) => asset.path === assetToLoad.path);
    if( assetIdx >= 0 ) {
      console.error("app.content.loadAsset - asset already exists: ", assetToLoad.path);
      return;
    }
    const asset: IAssetBase = {
      type: assetToLoad.type,
      path: assetToLoad.path,
      id: assetToLoad.id || assetToLoad.path, // if no unique id is provided, use path as unique id.
    };
    this.assets.push( asset );

    asset.loaded = false;
    asset.loading = true;
    asset.loadProgress = 0;

    // TODO: should assets that fail remain in this.assets array or be removed?

    if( asset.type === "model" ) {
      const assetModel = asset as IAssetModel;
      await this.loadModel( assetModel );
    } else if( asset.type === "pointcloud" ) {
      const assetPointCloud = asset as IAssetPointCloud;
      assetPointCloud.size = (assetToLoad as IAssetPointCloud).size;    // copy size from assetToLoad.
      assetPointCloud.color = (assetToLoad as IAssetPointCloud).color;  // copy color from assetToLoad.
      await this.loadPointCloud( assetPointCloud );
    } else if( asset.type === "texture" ) {
      const assetTexture = asset as IAssetTexture;
      await this.loadTexture( assetTexture );
    } else if( asset.type === "cubetexture" ) {
      const assetCubeTexture = asset as IAssetCubeTexture;
      await this.loadCubeTexture( assetCubeTexture );
    } else if( asset.type === "video" ) {
      const assetVideo = asset as IAssetVideo;
      await this.loadVideo( assetVideo );
    } else if( asset.type === "audio" ) {
      const assetAudio = asset as IAssetAudio;
      await this.loadAudio( assetAudio );
    }

    asset.loaded = true;
    asset.loading = false;
    asset.loadProgress = 1;

    this.loadAssetComplete( asset );
  }

  protected loadAssetComplete( _asset: IAssetBase ) {
    // override in subclass.
  }

  //----------------------------------------------------------------
  protected unloadAssets( assetsToUnload: IAssetBase[] ) {
    for( const assetToUnload of assetsToUnload ) {
      this.unloadAsset( assetToUnload );
    }
  }
  
  protected unloadAsset( assetToUnload:IAssetBase ) {
    const assetIdx = this.assets.findIndex((asset) => asset.path === assetToUnload.path);
    if( assetIdx < 0 ) {
      console.error("app.content.unloadAsset - asset does not exists: ", assetToUnload.path);
      return;
    }
    const asset = this.assets[assetIdx];
    if( asset.loading && asset.abortCtrl ) {
      asset.abortCtrl.abort();
    }
    if( asset.type === "model" ) {
      const assetModel = asset as IAssetModel;
      this.unloadModel( assetModel );
    } else if( asset.type === "pointcloud" ) {
      const assetPointCloud = asset as IAssetPointCloud;
      this.unloadPointCloud( assetPointCloud );
    } else if( asset.type === "texture" ) {
      const assetTexture = asset as IAssetTexture;
      this.unloadTexture( assetTexture );
    } else if( asset.type === "cubetexture" ) {
      const assetCubeTexture = asset as IAssetCubeTexture;
      this.unloadCubeTexture( assetCubeTexture );
    } else if( asset.type === "video" ) {
      const assetVideo = asset as IAssetVideo;
      this.unloadVideo( assetVideo );
    } else if( asset.type === "audio" ) {
      const assetAudio = asset as IAssetAudio;
      this.unloadAudio( assetAudio );
    }
    this.assets.splice(assetIdx, 1);
  }

  //----------------------------------------------------------------
  protected async loadModel( asset:IAssetModel ): Promise<void> {
    asset.abortCtrl = new AbortController();
    const { signal } = asset.abortCtrl;
    try {
      const response = await fetch( asset.path, { signal } );
      const blob = await response.blob();
      const fileName = `model-${Utils.UUID()}.glb`; // needs a unique name to avoid caching issues.
      const file = new File([blob], fileName, { type: "model/gltf-binary" });
      
      return new Promise<void>((resolve, reject) => {
        const onSuccess: SceneLoaderSuccessCallback = (meshes: AbstractMesh[], particleSystems: IParticleSystem[], skeletons: Skeleton[], animationGroups: AnimationGroup[]) => {
          meshes.forEach((mesh) => {
            if (!mesh.parent) {
              mesh.parent = this.rootNode!;
              asset.meshRoot = mesh;
            }
          });
          animationGroups.forEach((animGroup) => {
            animGroup.stop(); // stop from auto-playing.
          });          
          asset.meshes = meshes;
          asset.animations = animationGroups;
          resolve();
        };
        const onProgress = (event: ISceneLoaderProgressEvent) => {
          //console.log("app.content.loadModel.progress: " + event.loaded);
        };
        const onError = (scene: Scene, message: string, exception?: any) => {
          console.log("app.content.loadModel.error: " + message);
          reject(new Error(message));
        };
        SceneLoader.ImportMesh("", "", file, this.scene, onSuccess, onProgress, onError);
      });
    } catch (error) {
      if( error instanceof Error && error.name === "AbortError" ) {
        console.log("app.content.loadModel.abort: ", asset.id);
        return;
      }
      console.error("app.content.loadModel.error: ", error);
      throw error;
    }
  }

  protected async loadPointCloud( asset:IAssetPointCloud ): Promise<void> {
    asset.abortCtrl = new AbortController();
    const { signal } = asset.abortCtrl;
    try {
      const response = await fetch( asset.path, { signal } );
      const data = await parse(response, PLYLoader);
      const pos = data.attributes.POSITION.value;
      const col = data.attributes.COLOR_0.value;
      const num = pos.length / 3;
  
      const pointCloudSize = asset.size ?? 1;
      const pointsCloud = new PointsCloudSystem("pcs", pointCloudSize, this.scene!);
      pointsCloud.addPoints(num, (particle: { position: Vector3; color: Color4; }, i: number) => {
        const j = 3 * i;
        particle.position = new Vector3(
          pos[j+0], 
          pos[j+1], 
          pos[j+2]
        );
        particle.color = new Color4(
          asset.color ? asset.color[0] : col[j+0]/255.0,
          asset.color ? asset.color[1] : col[j+1]/255.0,
          asset.color ? asset.color[2] : col[j+2]/255.0,
          1.0
        );
      });
      const mesh = await pointsCloud.buildMeshAsync();
      mesh.parent = this.rootNode!;
      asset.mesh = mesh;
    } catch (error) {
      if( error instanceof Error && error.name === "AbortError" ) {
        console.log("app.content.loadPointCloud.abort: ", asset.id);
        return;
      }
      console.error("app.content.loadPointCloud.error: ", error);
      throw error;
    }
  }

  protected async loadTexture( asset:IAssetTexture ): Promise<void> {
    return new Promise((resolve, reject) => {
      const onLoad = () => {
        asset.texture = texture;
        resolve();
      }
      const onError = (message?: string, exception?: any) => {
        reject(new Error(`Failed to load texture: ${message}`));
      }
      const texture = new Texture( asset.path, this.scene!, undefined, false, undefined, onLoad, onError );
    });
  }

  protected async loadCubeTexture( asset:IAssetCubeTexture ): Promise<void> {
    return new Promise((resolve, reject) => {
      const onLoad = () => {
        asset.cubeTexture = cubeTexture;
        resolve();
      }
      const onError = (message?: string, exception?: any) => {
        reject(new Error(`Failed to load texture: ${message}`));
      }
      const cubeTexture = new CubeTexture( asset.path, this.scene!, undefined, false, undefined, onLoad, onError );
    });
  }

  protected async loadVideo( asset:IAssetVideo ): Promise<void> {
    return new Promise((resolve, reject) => {
      const addEventListeners = ( videoTexture:VideoTexture ) => {
        videoTexture.video.addEventListener("loadedmetadata", onLoad, { once: true }); // iOS fix.
        videoTexture.video.addEventListener("canplay", onLoad, { once: true });
        videoTexture.video.addEventListener("error", onError, { once: true });
      }
      const removeEventListeners = ( videoTexture:VideoTexture ) => {
        videoTexture.video.removeEventListener("loadedmetadata", onLoad);
        videoTexture.video.removeEventListener("canplay", onLoad);
        videoTexture.video.removeEventListener("error", onError);
      }
      const onLoad = ( _event: Event ) => {
        removeEventListeners( videoTexture );
        asset.videoTexture = videoTexture;
        videoTexture.video.pause();
        resolve();
      }
      const onError = ( _e?: any) => {
        removeEventListeners( videoTexture );
        reject(new Error(`Failed to load video: ${_e}`));
      }
      const settings:VideoTextureSettings = {
        autoPlay: true,
        autoUpdateTexture: true,
      };
      const videoTexture = new VideoTexture(
          "video",              // name
          asset.path,           // src
          this.scene!,          // scene
          undefined,            // generateMipMaps
          undefined,            // invertY 
          undefined,            // samplingMode
          settings,             // settings
          onError,              // onError
          undefined             // format
      );
      addEventListeners( videoTexture );
    });
  }

  protected async loadAudio( asset:IAssetAudio ): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const onLoad = () => {
          asset.audio = audio;
          resolve();
        }
        const audio = new Sound("audio", asset.path, this.scene, onLoad, {
          loop: false,
          autoplay: false,
          volume: 1
        });
      }
      catch (e) {
        reject(new Error(`Failed to load audio: ${e}`));
      }
    });
  }

  //----------------------------------------------------------------
  protected unloadModel( asset:IAssetModel ) {
    if( asset.meshes ) {
      asset.meshes.forEach((mesh) => {
        mesh.dispose();
      });
    }
    asset.meshes = undefined;
    asset.meshRoot = undefined;
  }

  protected unloadPointCloud( asset: IAssetPointCloud ) {
    if( asset.mesh ) {
      asset.mesh.dispose();
    }
    asset.mesh = undefined;
  }

  protected unloadTexture( asset: IAssetTexture ) {
    if( asset.texture ) {
      asset.texture.dispose();
      asset.texture = undefined;
    }
  }

  protected unloadCubeTexture( asset: IAssetCubeTexture ) {
    if( asset.cubeTexture ) {
      asset.cubeTexture.dispose();
      asset.cubeTexture = undefined;
    }
  }

  protected unloadVideo( asset: IAssetVideo ) {
    if( asset.videoTexture ) {
      asset.videoTexture.dispose();
      asset.videoTexture.video.src = "";
      asset.videoTexture.video.load();
      asset.videoTexture = undefined;
    }  
  }

  protected unloadAudio( asset: IAssetAudio ) {
    if( asset.audio ) {
      asset.audio.pause();
      asset.audio.dispose();
      asset.audio = undefined;
    }
  }
}