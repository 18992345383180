/* eslint-disable @typescript-eslint/no-unused-vars */

import ImmersalBase from "./immersal.base";
import { CameraIntrinsics, LocalizeResult, ResponseLocalizeImage } from "./immersal.common";
import BridgeImmersal from "../bridge/bridge.immersal";
import { Matrix } from "babylonjs";  // eventually remove dependency on BabylonJS

export default class ImmersalAppDevice extends ImmersalBase {
  
  private bridge: BridgeImmersal;
  
  constructor(token: string, mapIds: number[]) {
    super(token, mapIds);
    
    this.bridge = new BridgeImmersal();
  }

  public override async init(): Promise<void> {
    console.warn("immersal.app.device.init not required.");
    return Promise.resolve();
  }

  public override async loadMap(url: string): Promise<void> {
    return this.bridge.loadMapFromPath( url ) as Promise<void>;
  }

  public override async localize(_imageData?: Uint8ClampedArray, _imageWidth?: number, _imageHeight?: number, _intrinsics?: CameraIntrinsics): Promise<LocalizeResult> {
    try {
      const data : ResponseLocalizeImage = await this.bridge.localizeOnDevice() as ResponseLocalizeImage;
      if( data.error ) {
        throw new Error('immersal.app.device.localize: failed - ' + data.error);
      }
      const locResult: LocalizeResult = {
        success: false,
        confidence: 0,
      };
      if (data.success) {
        let matrix = new Matrix();
        matrix.setRowFromFloats(0, data.r00, -data.r01, -data.r02, data.px);
        matrix.setRowFromFloats(1, data.r10, -data.r11, -data.r12, data.py);
        matrix.setRowFromFloats(2, data.r20, -data.r21, -data.r22, data.pz);
        matrix.setRowFromFloats(3, 0, 0, 0, 1);
        matrix = matrix.transpose();

        locResult.success = true;
        locResult.confidence = data.confidence!;
        locResult.matrix = new Float32Array(16);
        matrix.toArray(locResult.matrix, 0);
        if( this.mapIds.length > 0 ) {
          locResult.map = this.mapIds[0]; // only one map when localizing on device.
        }
      }
      return locResult;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`${error.message || error}`);
      } else {
        throw new Error(String(error));
      }
    }
  }
}