import { useEffect, useState } from 'react';
import LocalStorage from '@/core/localstorage/localstorage';
import useUserDevice from './useUserDevice';
import Utils from '@/core/utils/utils';

const localStorage = new LocalStorage();
function useUserId() {
  const [userId, setUserId] = useState<string | null>(null);
  const { device } = useUserDevice();
  useEffect(() => {
    const handleUserId = async () => {
      const storedUserId = await localStorage.getItemAsync('userId');

      if (!storedUserId) {
        if (device === 'AppClip') {
          // get id from url params
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);
          const id = params.get('userId');
          if (id) {
            console.log('No ID exists in AppClip, setting new ID:', id);
            await localStorage.setItemAsync('userId', id);
            setUserId(id);
            return;
          }
        } else {
          const newUserId = Utils.UUID();
          await localStorage.setItemAsync('userId', newUserId);
          console.log('No ID exists, setting new ID:', newUserId);
          setUserId(newUserId);
          updateParams(newUserId);
        }
      } else {
        console.log('ID exists, get stored ID:', storedUserId);
        setUserId(storedUserId);
        updateParams(storedUserId);
      }
    };

    handleUserId();
  }, []);

  const updateParams = (id: string) => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.set('userId', id);
    const newUrl = `${url.origin}${url.pathname}?${params.toString()}${
      url.hash
    }`;
    window.history.replaceState(null, '', newUrl);
  };

  return userId;
}

export default useUserId;
