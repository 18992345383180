import { BaseTexture, RawTexture, Engine, Matrix, Quaternion, Vector3, WebXRDefaultExperience, ISize, WebXRRenderTarget, WebXRState } from 'babylonjs';
import AppImmersal from './app.immersal';
import { TextureDownsampler } from '../core/texture-downsampler';
import { CameraIntrinsics, LocalizeResult } from '../core/immersal/immersal.common';
import { EJApp } from '../core/utils/utils';
import { ErrorWebXR } from '../core/errors/error.webxr';

export default class AppImmersalWebXR extends AppImmersal {

  protected xr?: WebXRDefaultExperience;

  static DEFAULT_DOWNSAMPLER_RATIO = 1
  downsamplerRatio = AppImmersalWebXR.DEFAULT_DOWNSAMPLER_RATIO;
  private downsampler?: TextureDownsampler;
  private rawCameraTexture: BaseTexture | null = null;
  private pixelDataRgba = new Uint8ClampedArray();
  private pixelDataGrayscale = new Uint8ClampedArray();
  
  public constructor(renderCanvas: HTMLCanvasElement) {
    super( renderCanvas );
  }

  protected override initialize(): void {
    super.initialize();
    //
    const useDownsampler = false;
    if( useDownsampler ) {
      this.downsampler = new TextureDownsampler(this.engine!, {
        grayscale: true,
        flipY: true,
      });
    }
  }

  public override async startXR() {
    if( !this.xr ) {
      this.xr = await this.scene!.createDefaultXRExperienceAsync({
        disableDefaultUI: true,
      });
    }
    const isSupported = await this.xr.baseExperience.sessionManager.isSessionSupportedAsync('immersive-ar');
    if( !isSupported ) {
      const error = new Error('app.immersal.webxr.startXR: immersive-ar is not supported.');
      error.name = ErrorWebXR.NOT_SUPPPORTED;
      throw error;
    }
    const sessionMode: XRSessionMode = "immersive-ar";
    const referenceSpace: XRReferenceSpaceType = "local";
    const requiredFeatures = ['dom-overlay'];
    const optionalFeatures = ['camera-access'];
    const domOverlayElement = <Element>document.getElementById('domOverlay'); 
    const renderTarget: WebXRRenderTarget = this.xr!.renderTarget;
    const sessionCreationOptions: XRSessionInit = {
      requiredFeatures: requiredFeatures,
      optionalFeatures: optionalFeatures,
      domOverlay: {
        root: domOverlayElement 
      },
    }
    try {
      await this.xr!.baseExperience.enterXRAsync(sessionMode, referenceSpace, renderTarget, sessionCreationOptions);
    } catch( e ) {
      let permissionDenied = false;
      if( e instanceof DOMException ) {
        const domE = e as DOMException;
        permissionDenied = ( domE.name === 'NotSupportedError' );
        // NOTE: NotSupportedError could also mean a range of different errors, but here its assumed it is permission denied.
      }
      if( permissionDenied ) {
        const error = new Error('app.immersal.webxr.startXR: permission denied.');
        error.name = ErrorWebXR.PERMISSION_DENIED;
        throw error;
      } else {
        const error = new Error('app.immersal.webxr.startXR: undefined.');
        error.name = ErrorWebXR.UNDEFINED;
        throw error;
      }
    }
    this.xr!.baseExperience.onStateChangedObservable.add((state) => {
      if (state === WebXRState.EXITING_XR) {
        this.exitXREvent();
      }
    });
  }

  public override async stopXR() {
    if( !this.xr ) {
      return;
    }
    await this.xr.baseExperience.exitXRAsync();
  }

  public override async localizeAsync(): Promise<LocalizeResult> {
    return new Promise<LocalizeResult>((resolve, reject) => {
      if( this.localizing ) {
        reject(new Error('app.immersal.webxr.localize: already localizing.'));
        return;
      }
      if (!this.xr) {
        reject(new Error('app.immersal.webxr.localize: no xr.'));
        return;
      }
      const xrSessionManager = this.xr.baseExperience.sessionManager;
      if( !xrSessionManager.inXRSession ) {
        reject(new Error('app.immersal.webxr.localize: not in XR session.'));
        return;
      }
      xrSessionManager.runInXRFrame(async () => {
        const frame = xrSessionManager!.currentFrame;
        if (frame === null) {
          reject(new Error('app.immersal.webxr.localize: no frame.'));
          return;
        }
  
        if (!this.camera) {
          reject(new Error('app.immersal.webxr.localize: no active camera.'));
          return;
        }
  
        const referenceSpace = xrSessionManager.referenceSpace;
        const viewerPose = frame.getViewerPose(referenceSpace);
        if (!viewerPose) {
          reject(new Error('app.immersal.webxr.localize: no viewer pose.'));
          return;
        }
    
        const view = viewerPose.views[0];
        if (!view) {
          reject(new Error('app.immersal.webxr.localize: no view.'));
          return;
        }
       
        this.cameraMat = Matrix.FromArray(view.transform.inverse.matrix, 0).invert();
        const cameraMatScl = new Vector3(1, 1, 1);
        const cameraMatPos = new Vector3(0, 0, 0);
        const cameraMatRot = Quaternion.Identity();
        this.cameraMat.decompose(cameraMatScl, cameraMatRot, cameraMatPos);

        if( EJApp ) { // Runing in the EJ AppClip

          this.localize().then((res:LocalizeResult) => {
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
        
        } else {
          
          if (!view.camera) {
            reject(new Error('app.immersal.webxr.localize: no view camera.'));
            return;
          }
          
          const viewport: XRViewport = {
            x: 0,
            y: 0,
            width: view.camera.width,
            height: view.camera.height,
          };
      
          const intrinsics = this.getCameraIntrinsics(view.projectionMatrix, viewport);
          if (intrinsics === null) {
            reject(new Error('app.immersal.webxr.localize: no intrinsics.'));
            return;
          }
  
          const size = {
            width: Math.floor(view.camera.width),
            height: Math.floor(view.camera.height),
          }
  
          if( this.downsampler && this.downsamplerRatio !== 1 ) {
            // Intrinsics must be scaled by the texture downsample ratio.
            intrinsics.focalLength.x *= this.downsamplerRatio;
            intrinsics.focalLength.y *= this.downsamplerRatio;
            intrinsics.principalOffset.x *= this.downsamplerRatio;
            intrinsics.principalOffset.y *= this.downsamplerRatio;
            // Size must be scaled by the texture downsample ratio.
            size.width = Math.floor(size.width * this.downsamplerRatio);
            size.height = Math.floor(size.height * this.downsamplerRatio);
          }
  
          const imageDataResult = this.createCameraImageDataFromFrame(frame, size);
          if (!imageDataResult) throw new Error('app.immersal.webxr.localize: Could not get camera image data.');
          const { imageData, imageWidth, imageHeight } = imageDataResult;
  
          this.localize(imageData, imageWidth, imageHeight, intrinsics).then((res:LocalizeResult) => {
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
        }
      }, true);      
    })
  }
  
  private createCameraImageDataFromFrame(frame: XRFrame, size: ISize) {
    if (size.width * size.height === 0) {
      throw new Error(`app.immersal.webxr.createCameraImageDataFromFrame: Cannot downsample to an image with 0 pixels.  Recieved size ${size.width}x${size.height}.`)
    }

    if (this.rawCameraTexture) { // need to delete old texture to avoid memory leak
      (this.rawCameraTexture._texture!._hardwareTexture as any)._webGLTexture = null;
      this.rawCameraTexture.dispose();
    }

    const xrSessionManager = this.xr?.baseExperience.sessionManager;
    const referenceSpace = xrSessionManager!.referenceSpace;
    this.rawCameraTexture = this.createCameraTexture(this.engine!, referenceSpace, frame);
    if (this.rawCameraTexture === null) {
      return null;
    }
    if (this.downsampler) {
        this.rawCameraTexture = this.downsampler.downsample(this.rawCameraTexture, size);
    }
    const internalTexture = this.rawCameraTexture.getInternalTexture();
    if (!internalTexture) { 
      return null;
    }
    if (this.pixelDataRgba.length !== size.width * size.height * 4) {
        this.pixelDataRgba = new Uint8ClampedArray(size.width * size.height * 4);
        console.log(`pixelDataRgba resized ${size.width}x${size.height}x4=${size.width*size.height*4/1024}kb`)
    }
    this.engine!._readTexturePixelsSync(internalTexture, size.width, size.height, undefined, undefined, this.pixelDataRgba, true, true);

    if (this.pixelDataGrayscale.length !== size.width * size.height) {
        this.pixelDataGrayscale = new Uint8ClampedArray(size.width * size.height);
        console.log(`pixelDataRgba resized ${size.width}x${size.height}=${size.width*size.height/1024}kb`)
    }

    if (this.downsampler) {
        // Texture is already grayscaled by Texture Downsampler, but still uses 4 channels.
        for (let i = 0; i < this.pixelDataGrayscale.length; i++) {
          this.pixelDataGrayscale[i] = this.pixelDataRgba[i * 4];
        }
    } else {
      const imageWidth = internalTexture.width;
      const imageHeight = internalTexture.height;

      for (let kPel = 0; kPel < this.pixelDataGrayscale.length; kPel++) {
        const kFlip = AppImmersalWebXR.flip_index(
          kPel,
          imageWidth,
          imageHeight
        );

        const offset = 4 * kPel;
        const offsetFlip = kFlip;
        const r = this.pixelDataRgba[offset];
        const g = this.pixelDataRgba[offset + 1];
        const b = this.pixelDataRgba[offset + 2];
        const grey = 0.299 * r + 0.587 * g + 0.114 * b;
        this.pixelDataGrayscale[offsetFlip] = grey;
      }
    }

    return { 
      imageData: this.pixelDataGrayscale,
      imageWidth: size.width,
      imageHeight: size.height
    };
  }

  private static flip_index(kPel: number, width: number, height: number) {
    const i = Math.floor(kPel / width);
    const j = kPel % width;

    return height * width - (i + 1) * width + j;
  }

  private createCameraTexture(engine: Engine, referenceSpace: XRReferenceSpace, frame: XRFrame): BaseTexture | null {
    const viewerPose = frame.getViewerPose(referenceSpace);
  
    if (!viewerPose) {
      console.error('app.immersal.webxr.createCameraTexture: viewerPose is null');
      return null;
    }
    const view = viewerPose.views[0];
  
    const bindings = new XRWebGLBinding(frame.session, engine._gl);
    const cameraWebGLTexture = (bindings as any).getCameraImage(
      (view as any).camera
    ) as WebGLTexture;
  
    if (!cameraWebGLTexture) {
      console.error('app.immersal.webxr.createCameraTexture: cannot get camera WebGLTexture Object');
      return null;
    }
  
    const width: number = view.camera!.width;
    const height: number = view.camera!.height;
  
    const rawTexture = RawTexture.CreateRGBTexture(
      new Uint8Array(width * height * 4),
      width,
      height,
      null
    );
  
    (rawTexture._texture!._hardwareTexture as any)._webGLTexture = cameraWebGLTexture;
  
    return rawTexture;
  }
  
  private getCameraIntrinsics(projectionMatrix: Float32Array, viewport: XRViewport): CameraIntrinsics {
    const p = projectionMatrix;
  
    // Principal point in pixels (typically at or near the center of the viewport)
    const u0 = ((1 - p[8]) * viewport.width) / 2 + viewport.x;
    const v0 = ((1 - p[9]) * viewport.height) / 2 + viewport.y;
  
    // Focal lengths in pixels (these are equal for square pixels)
    const ax = (viewport.width / 2) * p[0];
    const ay = (viewport.height / 2) * p[5];
  
    return {
      principalOffset: {
        x: u0,
        y: v0,
      },
      focalLength: {
        x: ax,
        y: ay,
      },
    };
  }
}
