export const artworks = [
    {
        id: 'content-1',
        title: 'Pavlova',
        artist: 'The Dying Swan',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-2',
        title: 'Yeichi Nimura',
        artist: 'Dancing with cat',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-3',
        title: 'Tap Happening',
        artist: '',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-4',
        title: "Mama Lu Parks'",
        artist: 'Harvest Moon Ball',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-5',
        title: "Mama Lu Parks'",
        artist: 'Harvest Moon Ball',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-6',
        title: 'Pavlova',
        artist: 'The Dying Swan',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-7',
        title: 'Yeichi Nimura',
        artist: 'Dancing with cat',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-8',
        title: 'Tap Happening',
        artist: '',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-9',
        title: "Mama Lu Parks'",
        artist: 'Harvest Moon Ball',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
      {
        id: 'content-10',
        title: "Mama Lu Parks'",
        artist: 'Harvest Moon Ball',
        info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel tempor tellus, in posuere leo. Aliquam porttitor mauris at ligula luctus varius at sodales ligula. Cras eget turpis ut nunc luctus finibus vitae sed nunc. Etiam aliquet lobortis viverra. Suspendisse lobortis, elit malesuada suscipit feugiat, risus tortor condimentum nisi, vel aliquam tellus nisl ac est.',
        found: false,
      },
]