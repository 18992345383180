import BridgeLocalStorage from "../bridge/bridge.localstorage";
import LocalStorageBase from "./localstorage.base";

export default class LocalStorageApp extends LocalStorageBase {
  
  private bridge: BridgeLocalStorage;
  
  constructor() {
    super();
    this.bridge = new BridgeLocalStorage();
  }

  public override async setItemAsync(key: string, value: string): Promise<void> {
    return this.bridge.setItemAsync(key, value);
  }

  public override async getItemAsync(key: string): Promise<string | null> {
    // iOS implementation returns undefined when key is not found,
    // however it needs to be converted to null so that it is consistent with web implementation.
    return this.bridge.getItemAsync(key).then(value => value === undefined ? null : value);
  }

  public override async removeItemAsync(key: string): Promise<void> {
    return this.bridge.removeItemAsync(key);
  }

  public override async clearAsync(): Promise<void> {
    return this.bridge.clearAsync();
  }
}