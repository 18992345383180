import MediaRecorderBase from "./mediarecorder.base";
import BridgeMediaRecorder from "../bridge/bridge.mediarecorder";
import { MediaRecorderResponse } from "./mediarecorder.types";

export default class MediaRecorderApp extends MediaRecorderBase {
  
  private bridge: BridgeMediaRecorder;
  
  constructor() {
    super();
    this.bridge = new BridgeMediaRecorder();
  }

  public override async start(): Promise<MediaRecorderResponse> {
    return this.bridge.start() as unknown as MediaRecorderResponse;
  }

  public override async stop(): Promise<MediaRecorderResponse> {
    return this.bridge.stop() as unknown as MediaRecorderResponse;
  }
}