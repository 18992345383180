import { createContext, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Artwork } from '../types/Artwork';
import { artworks as initialArtworks } from '@/demos/webxr-template/data/artworks';
import useLocalStorage from '../hooks/useLocalStorage';

// Define the types for actions and state
type ArtworkFoundAction =
  | { type: 'ARTWORK_FOUND'; payload: string }
  | { type: 'SET_CURRENT_ARTWORK'; payload: string }
  | { type: 'CLEAR_CURRENT_ARTWORK' }
  | { type: 'INIT_ARTWORKS'; payload: Artwork[] };

export type ArtworkActionTypes = ArtworkFoundAction;

interface ArtworkState {
  artworks: Artwork[];
  current: string | null;
}

const initialArtworkState: ArtworkState = {
  artworks: [],
  current: null,
};

const artworkReducer = (state: ArtworkState, action: ArtworkActionTypes): ArtworkState => {
  switch (action.type) {
    case 'INIT_ARTWORKS':
      return {
        ...state,
        artworks: action.payload, 
      };
    case 'ARTWORK_FOUND':
      return {
        ...state,
        artworks: state.artworks.map((artwork) =>
          artwork.id === action.payload ? { ...artwork, found: true } : artwork
        ),
      };
    case 'SET_CURRENT_ARTWORK':
      return {
        ...state,
        current: action.payload,
        artworks: state.artworks.map((artwork) =>
          artwork.id === action.payload ? { ...artwork, found: true } : artwork
        ),
      };
    case 'CLEAR_CURRENT_ARTWORK':
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

interface ArtworkContextProps extends ArtworkState {
  setCurrentArtwork: (artwork: string) => void;
  clearCurrentArtwork: () => void;
  numArtworksFound: number;
  artworks: Artwork[];
  currentArtwork: Artwork | null;
}

export const ArtworkContext = createContext<ArtworkContextProps>({
  ...initialArtworkState,
  setCurrentArtwork: () => {},
  clearCurrentArtwork: () => {},
  numArtworksFound: 0,
  currentArtwork: null,
});

export type ArtworkProviderProps = {
  children: React.ReactNode;
};

export const ArtworkProvider = ({ children }: ArtworkProviderProps) => {
  const [artworkFoundIds, setArtworkFoundIds] = useLocalStorage('artworkFoundIds', []);
  const [artworksState, dispatch] = useReducer(artworkReducer, initialArtworkState);
  const currentArtwork = useRef<Artwork | null>(null);
  const [artworksFound, setArtworksFound] = useState(0);

  useEffect(() => {
    const initializeArtworks = async () => {

      const newArtworks = initialArtworks.map((artwork) =>
        artworkFoundIds.includes(artwork.id)
          ? { ...artwork, found: true }
          : { ...artwork, found: false }
      );

      dispatch({ type: 'INIT_ARTWORKS', payload: newArtworks });
      setArtworksFound(artworkFoundIds.length);
    };

    initializeArtworks();
  }, [artworkFoundIds]);

  const setCurrentArtwork = useCallback(
    async (id: string) => {
      const newCurrentArtwork = artworksState.artworks.find((artwork: Artwork) => artwork.id === id);
      if (newCurrentArtwork) {
        currentArtwork.current = newCurrentArtwork;

        if (!artworkFoundIds.includes(id)) {
          artworkFoundIds.push(id);
          setArtworkFoundIds(artworkFoundIds);
          setArtworksFound(artworkFoundIds.length);
        }
        dispatch({ type: 'SET_CURRENT_ARTWORK', payload: id });
      } else {
        if (id === "") {
          currentArtwork.current = null;
          dispatch({ type: 'SET_CURRENT_ARTWORK', payload: id });
        } else {
          console.warn(`Artwork with id ${id} not found.`);
        }
      }
    },
    [artworksState.artworks, artworkFoundIds, setArtworkFoundIds]
  );

  const clearCurrentArtwork = useCallback(() => {
    dispatch({ type: 'CLEAR_CURRENT_ARTWORK' });
  }, []);

  const contextValue = useMemo(
    () => ({
      ...artworksState,
      currentArtwork: currentArtwork.current,
      setCurrentArtwork,
      clearCurrentArtwork,
      numArtworksFound: artworksFound,
    }),
    [artworksState, currentArtwork, setCurrentArtwork, clearCurrentArtwork, artworksFound]
  );

  return <ArtworkContext.Provider value={contextValue}>{children}</ArtworkContext.Provider>;
};
