import OcclusionMesh from "@lincolncenter/building/LC_OCCLUSION.glb";
import ContentLincolnCenterBase from "./content.lincolncenter.base";
import { IAssetBase, IAssetModel } from "@/apps/app.content";
import { Occlusion } from "./content.lincolncenter.types";
import { IContentLincolnCenterConfig } from "./content.lincolncenter.config";
import { Effect, Mesh, ShaderMaterial } from "babylonjs";

export default class ContentLincolnCenterOcclusion extends ContentLincolnCenterBase {
  private _config: IContentLincolnCenterConfig | undefined;

  constructor() {
    super();
    this.id = Occlusion;
  }

  public set config(config: IContentLincolnCenterConfig) {
    this._config = config;
  }

  public get assets(): IAssetBase[] {
    return [
      {
        id: `${Occlusion}-mesh`,
        type: "model",
        path: OcclusionMesh,
      },
    ];
  }

  public loadAssetComplete(_asset: IAssetBase): void {
    if (_asset.id === `${Occlusion}-mesh`) {
      const model = _asset as IAssetModel;
      if (model && this._config) {
        if (this._config.occlusion) {
          model.meshRoot?.position.copyFrom(this._config.occlusion.position);
          model.meshRoot?.rotation.copyFrom(this._config.occlusion.rotation);
          model.meshRoot?.scaling.copyFrom(this._config.occlusion.scale);
          Effect.ShadersStore["depthOnlyVertexShader"] = `
            precision highp float;
            attribute vec3 position;
            uniform mat4 worldViewProjection;

            void main() {
                gl_Position = worldViewProjection * vec4(position, 1.0);
            }
        `;

            BABYLON.Effect.ShadersStore["depthOnlyFragmentShader"] = `
            precision highp float;

            void main() {
              gl_FragColor = vec4(1.0);
            }
        `;

          const depthOnlyMaterial = new ShaderMaterial("depthOnlyMaterial", this.scene!, {
            vertex: "depthOnly",
            fragment: "depthOnly",
          }, {
              attributes: ["position"],
              uniforms: ["worldViewProjection"],
              needAlphaBlending: false, // No color blending needed
              needAlphaTesting: false,
          });

          depthOnlyMaterial.onBind = (mesh) => {
            this.scene!.getEngine().setColorWrite(false);
          };
        
          depthOnlyMaterial.depthFunction = 0;
          depthOnlyMaterial.backFaceCulling = false;
          depthOnlyMaterial.forceDepthWrite = true;
          // Apply the material to the mesh
          model.meshRoot!.material = depthOnlyMaterial;

          // to each mesh in the model
          model.meshes?.forEach((mesh) => {
            mesh.material = depthOnlyMaterial;

          (mesh as Mesh).onBeforeRenderObservable.add(() => this.engine!.setColorWrite(false));

          (mesh as Mesh).onAfterRenderObservable.add(() => this.engine!.setColorWrite(true));
          });

        } else {
          if (model.meshRoot) {
            model.meshRoot!.isVisible = false;
            model.meshes?.forEach((mesh) => {
              (mesh as Mesh).isVisible = false;
            });
          }
        }
      }
    }
  }
}
