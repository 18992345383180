import ModelPath from '@lincolncenter/idrani/idrani-model.glb';
import VideoPath from '@lincolncenter/idrani/idrani-video.mp4';

//
import { Constants, Mesh, Scene, Vector4, VideoTexture } from 'babylonjs';
import ContentLincolnCenterModelVideo from './content.lincolncenter.modelvideo';
import { getUVBoundingBox, initAlphaVideoShaderMaterial } from '../core/content.common';
import { Idrani } from './content.lincolncenter.types';

export default class ContentLincolnCenterIdrani extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = Idrani;
    this.modelPath = ModelPath;
    this.videoPath = VideoPath;
    this.videoMaterialName = 'Video';
    this.checkVideoSynchronization = false;
  }

  protected override applyAlphaVideoToMesh( mesh:Mesh, videoTexture:VideoTexture, scene:Scene ): void {
    const uvBoxRange = getUVBoundingBox( mesh ); // uvBox for original uv coords.
    const uvBoxColor = { // adjusted uvBox to match uv coords for color in vidueo texture.
      minU: uvBoxRange.minU,
      maxU: uvBoxRange.maxU,
      minV: 1.0 - uvBoxRange.minV,
      maxV: 1.0 - uvBoxRange.maxV,
    }
    const uvBoxAlpha = { // adjusted uvBox to match uv coords for alpha in vidueo texture.
      minU: uvBoxColor.minU + 0.5,
      maxU: uvBoxColor.maxU + 0.5,
      minV: uvBoxColor.minV,
      maxV: uvBoxColor.maxV,
    }
    const uvRange = new Vector4( uvBoxRange.minU, uvBoxRange.minV, uvBoxRange.maxU, uvBoxRange.maxV );
    const uvColor = new Vector4( uvBoxColor.minU, uvBoxColor.minV, uvBoxColor.maxU, uvBoxColor.maxV );
    const uvAlpha = new Vector4( uvBoxAlpha.minU, uvBoxAlpha.minV, uvBoxAlpha.maxU, uvBoxAlpha.maxV );
    //
    const material = initAlphaVideoShaderMaterial( scene, videoTexture, uvRange, uvColor, uvAlpha );
    material.alphaMode = Constants.ALPHA_PREMULTIPLIED;
    material.backFaceCulling = false;
    mesh.material = material;
  }

}