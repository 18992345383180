/* eslint-disable @typescript-eslint/no-unused-vars */

import _114026_sparse from '../../../assets/projects/lincolncenter/map/114026-LC_middle_BLK2GO_scan2-sparse.ply';
import _114028_sparse from '../../../assets/projects/lincolncenter/map/114028-LC_topleft_BLK2GO_scan13-sparse.ply';
import _114031_sparse from '../../../assets/projects/lincolncenter/map/114031-LC_topright_BLK2GO_scan5-sparse.ply';
//
import emitter from '@/core/events/eventemitter';
import { AbstractMesh, Angle, HemisphericLight, StandardMaterial, Vector3 } from "babylonjs";
import { IAssetBase } from "../../apps/app.content";
import ContentLincolnCenter from "@/content/lincolncenter/content.lincolncenter";
import { IContentLincolnCenterConfig, ILincolnCenterConfig, LincolnCenterOffsiteConfig, LincolnCenterOnsiteConfig } from "@/content/lincolncenter/content.lincolncenter.config";
import AppSim3d from "../../apps/app.sim3d";
import AppImmersalWebXR from "@/apps/app.immersal.webxr";
import { AppImmersalConfig } from "@/apps/app.immersal";

import OffsiteLargeConfig from "@lincolncenter/scene/offsite-large-graph.json";
import OffsiteSmallConfig from "@lincolncenter/scene/offsite-small-graph.json";
import { ContentLincolnCenterStates } from '@/content/lincolncenter/content.lincolncenter.states';

//----------------------------------------------------------------
function LaunchAppSim3d( configLC: ILincolnCenterConfig ) {
  class Main extends AppSim3d {

    private content: ContentLincolnCenter;
    private completedCalibrationVisuals: boolean = false;

    constructor( config: IContentLincolnCenterConfig ) {
      super();
      //
      this.content = new ContentLincolnCenter( 
        config,
        this.loadContents.bind(this),
        this.unloadContents.bind(this)
      );
      this.content.isSim3D = true;

      emitter.on('content-play', (id: string) => {
        this.toggleContentVisibility(id, true);
      });

      emitter.on('content-unload', (id: string) => {
        this.toggleContentVisibility(id, false);
      });

      emitter.on('content-state', (state: ContentLincolnCenterStates) => {
        this.content.switchState(state);
      }); 

      // For testing in Sim3D
      window.emitter = emitter;

      emitter.on('xr-start', () => {
        emitter.emit('content-state', ContentLincolnCenterStates.NONE);
      })

      emitter.on('xr-started', () => {
        this.content.start();
      });

    }

    protected override initialize(): void {
      super.initialize();
      this.content.init( this.engine!, this.scene!, this.camera!, this.rootNode, this.renderTarget );
    }

    public override async loadAsync() {
      await this.loadContents( this.content.assets );
    }

    protected override loadAssetComplete( asset: IAssetBase ) {
      console.log('loadAssetComplete: ', asset.id);
      this.content.loadAssetComplete( asset );
      if (asset.id === 'building_mesh' || ((asset.id && asset.id.startsWith('immersal')) && configType?.startsWith('offsite'))) {
        this.toggleContentVisibility(asset.id, false);
      }
    }

    protected wireframeMesh(meshes: AbstractMesh[]) {
      meshes?.forEach((mesh) => {
        const wireframeMat = new StandardMaterial('wireframeMat', this.scene);
        wireframeMat.wireframe = true;
        mesh.material = wireframeMat;
      });
    }

    protected override render(): void {
      super.render();
      this.content.render();
    }

    protected override renderPost(): void {
      super.renderPost();
      this.content.renderPost();
    }

    protected override resize(): void {
      super.resize();
      this.content.resize();
    }

    protected toggleContentVisibility(id: string, isVisible: boolean) {
        this.content.toggleContentVisibility(id, isVisible);
    }
  }
  //
  const app = new Main(configLC.configContentLincolnCenter);
  app.setConfigContent(configLC.configContent);
  app.setConfigSim3d(configLC.configSim3d);
  app.init();
  console.log('AppSim3d.initialized');
  // await app.loadAsync();
  console.log('AppSim3d.loaded');
}

//----------------------------------------------------------------
function LaunchAppWebXR( configLC: ILincolnCenterConfig ) {
  
  class Main extends AppImmersalWebXR {
    private content: ContentLincolnCenter;
  
    constructor(config: IContentLincolnCenterConfig) {
      const renderCanvas = <HTMLCanvasElement>(
        document.getElementById("renderCanvas")
      );
      super(renderCanvas);
      //
      this.content = new ContentLincolnCenter(
        config,
        this.loadContents.bind(this),
        this.unloadContents.bind(this)
      );
      emitter.on("content-play", (id: string) => {
        this.toggleContentVisibility(id, true);
      });
  
      emitter.on("content-unload", (id: string) => {
        this.toggleContentVisibility(id, false);
      });

      emitter.on('xr-started', () => {
        this.content.start();
      });

      emitter.on('content-state', (state: ContentLincolnCenterStates) => {
        this.content.switchState(state);
      }); 

    }
  
    protected override initialize(): void {
      super.initialize();
      this.content.init(this.engine!, this.scene!, this.camera!, this.rootNode);
      const light = new HemisphericLight(
        "light",
        new Vector3(0, 1, 0),
        this.scene
      );
      light.intensity = 0.7;
      this.rootNode!.position.y = -1.2;
    }
  
    public override async loadAsync() {
      await this.loadContents(this.content.assets);
      await super.loadAsync();
    }
  
    protected override loadAssetComplete(asset: IAssetBase) {
      console.log("loadAssetComplete: ", asset.id);
      this.content.loadAssetComplete(asset);
      if (asset.id === 'building_mesh' || ((asset.id && asset.id.startsWith('immersal')) && configType?.startsWith('offsite'))) {
        this.toggleContentVisibility(asset.id, false);
      }
    }
  
    protected override render(): void {
      super.render();
      this.content.render();
    }
  
    protected override renderPost(): void {
      super.renderPost();
      this.content.renderPost();
    }
  
    protected override resize(): void {
      super.resize();
      this.content.resize();
    }
  
    protected toggleContentVisibility(id: string, isVisible: boolean) {
      this.content.toggleContentVisibility(id, isVisible);
    }

    protected wireframeMesh(meshes: AbstractMesh[]) {
      meshes?.forEach((mesh) => {
        const wireframeMat = new StandardMaterial('wireframeMat', this.scene);
        wireframeMat.wireframe = true;
        mesh.material = wireframeMat;
      });
    }
  }
  
  //----------------------------------------------------------------
  const MapMiddle = 114026;
  const MapTopLeft = 114028;
  const MapTopRight = 114031;
  
  const configImmersal = new AppImmersalConfig({
    token: '0d9e70de2e6dad37060de2019f11f272c00fc6087fbecf803a4cffec2b24b01c',
    localizeOnDevice: false,
    maps: [
      {
        mapId: MapMiddle,
        position: [-33.486, 1.62, 76.900],
        rotation: [0, Angle.FromDegrees(81.90).radians(), 0],
      },
      {
        mapId: MapTopLeft,
        position: [-28, 1.0, 8.5],
        rotation: [0, Angle.FromDegrees(272.3).radians(), 0],
      },
      {
        mapId: MapTopRight,
        position: [-7.1, 2, -56.6],
        rotation: [0, Angle.FromDegrees(113).radians(), 0],
      }
    ],
  });
  //
  const app = new Main(configLC.configContentLincolnCenter);
  app.setConfigContent(configLC.configContent);
  app.setConfigImmersal(configImmersal);
  app.init();  
}

//----------------------------------------------------------------
const AppTypeSim3d = "sim3d";
const AppTypeWebXR = "webxr";
const ConfigTypeOnsite = "onsite";
const ConfigTypeOnsiteNoGeoGate = "onsite-nogeogate";
const ConfigTypeOffsiteSmall = "offsite-small";
const ConfigTypeOffsiteLarge = "offsite-large";

const queryString = window.location.search;
const params = new URLSearchParams(queryString);
const appType = params.get("app");
const configType = params.get("config");

let LaunchFunc = undefined;
if( appType === AppTypeWebXR ) {
  LaunchFunc = LaunchAppWebXR;
} else if( appType === AppTypeSim3d ) {
  LaunchFunc = LaunchAppSim3d;
} else {
  LaunchFunc = LaunchAppWebXR; // default.
}

let configLC: ILincolnCenterConfig | undefined = undefined
if( configType && configType.startsWith(ConfigTypeOnsite) ) {
  const geogate = (configType === ConfigTypeOnsiteNoGeoGate) ? false : true;
  configLC = LincolnCenterOnsiteConfig();
  configLC.configContentLincolnCenter.geogate = geogate;
} else if( configType === ConfigTypeOffsiteLarge ) {
  configLC = LincolnCenterOffsiteConfig(OffsiteLargeConfig);
} else if (configType === ConfigTypeOffsiteSmall) {
  configLC = LincolnCenterOffsiteConfig(OffsiteSmallConfig);
} else {
  configLC = LincolnCenterOnsiteConfig(); // default.
}

LaunchFunc( configLC );