import {
  AbstractMesh,
  Camera,
  Color4,
  Effect,
  Engine,
  Mesh,
  RenderTargetTexture,
  Scene,
  ShaderMaterial,
  TransformNode,
  VertexData,
} from "babylonjs";
import MediaRecorder from "@/core/mediarecorder/mediarecorder";
import { MediaRecorderResponse } from "@/core/mediarecorder/mediarecorder.types";
import Share from "@/core/share/share";
import { ShareResponse } from "@/core/share/share.types";
import AppBase from "./app.base";

export default class AppBabylon extends AppBase {
  protected renderCanvas: HTMLCanvasElement;
  protected engine?: Engine;
  protected scene?: Scene;
  protected camera?: Camera;
  protected rootNode?: TransformNode;
  protected renderTarget: RenderTargetTexture | undefined;
  protected fullscreenQuad?: Mesh;
  protected mediaRecorder?: MediaRecorder;
  protected share?: Share;
  protected timeLast = 0;
  protected timeDelta = 0;

  constructor(renderCanvas: HTMLCanvasElement) {
    super();
    this.renderCanvas = renderCanvas;
  }

  protected override initialize() {
    this.initEngine();
    this.initScene();
    this.initCamera();
    this.initRootNode();
    this.initRenderTarget();
    // this.initFullScreenQuad();
    this.initMediaRecorder();
    this.initShare();
    this.initCallbacks();
  }

  protected initEngine() {
    this.engine = new Engine(this.renderCanvas, true);
  }

  protected initScene() {
    this.scene = new Scene(this.engine!);
    this.scene.useRightHandedSystem = true;
    this.scene!.clearColor = new Color4(0, 0, 0, 0);
  }

  protected initCamera() {
    this.scene!.createDefaultCamera(true, true, true); // creates an ArcCamera by default.
    this.camera = this.scene!.activeCamera!;
    this.camera.position.set(0, 0, 0); // default camera does not initilize at (0,0,0)! z=-1 by default (weird?)
  }

  protected initRootNode() {
    this.rootNode = new TransformNode("modelNode", this.scene);
  }

  protected initRenderTarget() {
    this.renderTarget = new RenderTargetTexture(
      "renderTarget",
      {
        width: this.engine!.getRenderWidth(),
        height: this.engine!.getRenderHeight(),
      },
      this.scene!,
      false
    );
    // set meshes for render target
    this.renderTarget.renderList = [];
    // loop through and add all meshes to render target
    this.scene!.meshes.forEach((mesh) => {
      if (mesh instanceof AbstractMesh) {
        this.renderTarget!.renderList!.push(mesh);
      }
    });
    //callback for when new mesh is added to scene
    this.scene!.onNewMeshAddedObservable.add((mesh) => {
      if (mesh instanceof AbstractMesh) {
        this.renderTarget!.renderList!.push(mesh);
      }
    });
    this.scene!.customRenderTargets.push(this.renderTarget);
  }

  protected initFullScreenQuad() {
    Effect.ShadersStore["fullscreenVertexShader"] = `
      precision highp float;

      // Attributes
      attribute vec2 position;

      // Varyings
      varying vec2 vUV;

      void main(void) {
        // Calculate UV
        vUV = position * 0.5 + 0.5;
        gl_Position = vec4(position, 0.0, 1.0);
      }
    `;
    // Fragment shader
    Effect.ShadersStore["fullscreenFragmentShader"] = `
    precision highp float;

    // Varying
    varying vec2 vUV;
    uniform sampler2D textureSampler;

    void main(void) {
        vec4 col = texture2D(textureSampler, vUV);
        gl_FragColor = col;
    }
    `;
    const shaderMat = new ShaderMaterial(
      "shader",
      this.scene!,
      {
        vertex: "fullscreen",
        fragment: "fullscreen",
      },
      {
        attributes: ["position"],
        uniforms: ["world", "view", "projection", "fov", "aspect", "nearPlane"],
      }
    );
    // Define vertex positions in NDC
    const positions = [
      -1,
      -1,
      0, // Bottom-left
      1,
      -1,
      0, // Bottom-right
      1,
      1,
      0, // Top-right
      -1,
      1,
      0, // Top-left
    ];
    // Define indices for two triangles
    const indices = [
      0,
      1,
      2, // First triangle
      0,
      2,
      3, // Second triangle
    ];
    // Define UV coordinates
    const uvs = [
      0,
      0, // Bottom-left
      1,
      0, // Bottom-right
      1,
      1, // Top-right
      0,
      1, // Top-left
    ];
    // Create a custom mesh
    this.fullscreenQuad = new Mesh("fullscreenQuad", this.scene!);
    this.fullscreenQuad.renderingGroupId =  3;
    this.fullscreenQuad.alwaysSelectAsActiveMesh = true;
    this.fullscreenQuad.isPickable = false;
    
    shaderMat.backFaceCulling = false;
    shaderMat.zOffset = -100;
    shaderMat.disableDepthWrite = true;

    // set texture as rtt
    if (this.renderTarget) {
      shaderMat.setTexture("textureSampler", this.renderTarget);
    }
    this.fullscreenQuad.material = shaderMat;

    // Create and apply vertex data
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.uvs = uvs;
    vertexData.applyToMesh(this.fullscreenQuad);
  }

  protected initMediaRecorder() {
    this.mediaRecorder = new MediaRecorder();
  }

  protected initShare() {
    this.share = new Share();
  }

  protected initCallbacks() {
    this.engine!.runRenderLoop(this.renderCallback.bind(this));
    window.addEventListener("resize", this.resizeCallback.bind(this));
  }

  protected renderCallback() {
    const now = performance.now() / 1000.0;
    if (this.timeLast === 0) {
      this.timeLast = now;
    }
    this.timeDelta = now - this.timeLast;
    this.timeLast = now;
    //
    this.render();

    this.scene!.render();
    // this.renderTarget?.render();
  }

  protected resizeCallback() {
    this.engine!.resize();
    this.resize();
  }

  protected render() {
    // override this method in the derived class.
  }

  protected resize() {
    // override this method in the derived class.
  }

  //----------------------------------------------------------------
  public override recordStartAsync(): Promise<MediaRecorderResponse> {
    if (!this.mediaRecorder) {
      return Promise.reject(
        new Error("app.babylon - MediaRecorder is not initialized")
      );
    }
    return this.mediaRecorder.start();
  }

  public override recordStopAsync(): Promise<MediaRecorderResponse> {
    if (!this.mediaRecorder) {
      return Promise.reject(
        new Error("app.babylon - MediaRecorder is not initialized")
      );
    }
    return this.mediaRecorder.stop();
  }

  //----------------------------------------------------------------
  public override shareAsync(shareData: ShareData): Promise<ShareResponse> {
    if (!this.share) {
      return Promise.reject(
        new Error("app.babylon - Share is not initialized")
      );
    }
    return this.share.share(shareData);
  }
}