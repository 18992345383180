import MediaRecorderBase from "./mediarecorder.base";
import { MediaRecorderResponse } from "./mediarecorder.types";

export default class MediaRecorderWeb extends MediaRecorderBase {

  constructor() {
    super();

  }

  public async start(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("mediarecorder.web.start has not been implemeted yet"));
  }

  public async stop(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("mediarecorder.web.stop has not been implemeted yet"));
  }
}