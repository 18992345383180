import { EJApp } from "../utils/utils";
import ShareApp from "./share.app";
import ShareBase from "./share.base";
import { ShareResponse } from "./share.types";
import ShareWeb from "./share.web";

export default class Share extends ShareBase {
  
  private impl: ShareBase;
  
  constructor() {
    super();
    if( EJApp ) {
      this.impl = new ShareApp();
    } else {
      this.impl = new ShareWeb();
    }
  }

  public async share( data:ShareData ): Promise<ShareResponse> {
    return this.impl.share( data );
  }

}