import Utils from "../utils/utils";
import Bridge, { BridgeMessage } from "./bridge";
import { BridgeMessageTypeMediaRecorderStart, BridgeMessageTypeMediaRecorderStop } from "./bridge.types";

export default class BridgeMediaRecorder extends Bridge {
  constructor() {
    super();
  }
    
  public async start(): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeMediaRecorderStart,
        data: undefined,
      };
      this.send(message, resolve, reject);
    });
  }

  public async stop(): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeMediaRecorderStop,
        data: undefined,
      };
      this.send(message, resolve, reject);
    });
  }
}