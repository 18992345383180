import { Camera, Color3, Color4, Constants, Effect, Engine, FreeCamera, Matrix, Mesh, MeshBuilder, Ray, RenderTargetTexture, RenderTargetTextureOptions, Scene, ShaderMaterial, StandardMaterial, Texture, Vector2, Vector3, Vector4, VertexBuffer, VideoTexture, WebXRCamera } from "babylonjs";
import { drawSphereAt } from "../lincolncenter/utils/content.lincolncenter.debug";
import { EJApp } from "@/core/utils/utils";

//----------------------------------------------------------------
export function initAlphaVideoShaderMaterial( scene:Scene, videoTexture:VideoTexture, uvRange?:Vector4, uvColor?:Vector4, uvAlpha?:Vector4 ): ShaderMaterial {
  const shaderName = "alphaVideo";
  const shaderNameVert = `${shaderName}VertexShader`;
  const shaderNameFrag = `${shaderName}FragmentShader`;
  if( !Effect.ShadersStore[shaderNameVert] ) {
    Effect.ShadersStore[shaderNameVert] = `
      precision highp float;
      attribute vec3 position;
      attribute vec2 uv;
      uniform mat4 worldViewProjection;
      varying vec2 vUV;

      void main(void) {
          gl_Position = worldViewProjection * vec4(position, 1.0);
          vUV = uv;
      }
    `;
  }
  if( !Effect.ShadersStore[shaderNameFrag] ) {
    Effect.ShadersStore[shaderNameFrag] = `
      precision highp float;
      varying vec2 vUV;
      uniform sampler2D videoTexture;
      uniform vec4 uvRange;
      uniform vec4 uvColor;
      uniform vec4 uvAlpha;
      uniform float alpha;

      float remap(float value, float inMin, float inMax, float outMin, float outMax) {
          float normalized = (value - inMin) / (inMax - inMin);
          return outMin + normalized * (outMax - outMin);
      }

      void main(void) {
        vec2 uvColor2;
        uvColor2.x = remap(vUV.x, uvRange.x, uvRange.z, uvColor.x, uvColor.z);
        uvColor2.y = remap(vUV.y, uvRange.y, uvRange.w, uvColor.y, uvColor.w);
      
        vec2 uvAlpha2;
        uvAlpha2.x = remap(vUV.x, uvRange.x, uvRange.z, uvAlpha.x, uvAlpha.z);
        uvAlpha2.y = remap(vUV.y, uvRange.y, uvRange.w, uvAlpha.y, uvAlpha.w);

        vec4 colorSample = texture2D(videoTexture, uvColor2);
        vec3 colorPremultipliedAlpha = colorSample.rgb * alpha; // premultiply alpha.
        vec4 alphaSample = texture2D(videoTexture, uvAlpha2);

        gl_FragColor = vec4(colorPremultipliedAlpha, alphaSample.r * alpha);
      }
    `;
  }
  const alphaVideoShaderMaterial = new ShaderMaterial("alphaVideoShaderMaterial", scene, {
    vertex: shaderName,
    fragment: shaderName,
  }, {
      attributes: ["position", "uv"],
      uniforms: ["worldViewProjection", "alpha"],
      samplers: ["videoTexture"],
      needAlphaBlending: true,
  });
  if( !uvRange ) uvRange = new Vector4(0.0, 0.0, 1.0, 1.0); // default uv range.
  if( !uvColor ) uvColor = new Vector4(0.0, 0.0, 0.5, 1.0); // default color uv layout (first horizontal half of video).
  if( !uvAlpha ) uvAlpha = new Vector4(0.5, 0.0, 1.0, 1.0); // default alpha uv layout (second horizontal half of video).
  alphaVideoShaderMaterial.setTexture("videoTexture", videoTexture);
  alphaVideoShaderMaterial.setVector4("uvRange", uvRange);
  alphaVideoShaderMaterial.setVector4("uvColor", uvColor);
  alphaVideoShaderMaterial.setVector4("uvAlpha", uvAlpha);
  alphaVideoShaderMaterial.setFloat("alpha", 1.0);
  return alphaVideoShaderMaterial;
}

//----------------------------------------------------------------
export function initAlphaVideoRTT(scene:Scene, videoTexture:VideoTexture) { // NOTE: not needed anymore, however keeping for reference.
  const alphaVideoShaderMaterial = initAlphaVideoShaderMaterial( scene, videoTexture );
  const plane = MeshBuilder.CreatePlane("plane", { size: 2 }, scene);
  plane.material = alphaVideoShaderMaterial;
  plane.setEnabled(false);
  //
  const orthoCamera = new FreeCamera("orthoCamera", new Vector3(0, 0, -10), scene);
  orthoCamera.mode = Camera.ORTHOGRAPHIC_CAMERA;
  orthoCamera.orthoLeft = -1;
  orthoCamera.orthoRight = 1;
  orthoCamera.orthoTop = 1;
  orthoCamera.orthoBottom = -1;
  //
  const options: RenderTargetTextureOptions = { format: Constants.TEXTUREFORMAT_RGBA, type: Constants.TEXTURETYPE_UNSIGNED_INT };
  const videoRTT = new RenderTargetTexture("alphaVideoTexture", { width: 1024, height: 1024 }, scene, options);
  videoRTT.clearColor = new Color4(0, 0, 0, 0);
  videoRTT.hasAlpha = true;
  videoRTT.renderList!.push(plane);
  videoRTT.activeCamera = orthoCamera;
  videoRTT.onBeforeRenderObservable.add(() => {
    plane.setEnabled(true);
  });
  videoRTT.onAfterRenderObservable.add(() => {
    plane.setEnabled(false);
  });    
  scene.customRenderTargets.push(videoRTT);
  //
  const showDebugPlane = false;
  if( showDebugPlane ) {
    const planeDebug = MeshBuilder.CreatePlane("plane", { size: 2 }, scene);
    planeDebug.material = initAlphaVideoShaderMaterial( scene, videoTexture );
    planeDebug.rotate(new Vector3(0, 1, 0), Math.PI); // face camera.
    planeDebug.position = new Vector3(0, -2, 0);
  }
}

//----------------------------------------------------------------
export function initUnlitTextureShaderMaterial( scene: Scene, texture: Texture ): ShaderMaterial {
  const shaderName = "unlitTexture";
  const shaderNameVert = `${shaderName}VertexShader`;
  const shaderNameFrag = `${shaderName}FragmentShader`;
  if( !Effect.ShadersStore[shaderNameVert] ) {
    Effect.ShadersStore[shaderNameVert] = `
      precision highp float;
      attribute vec3 position;
      attribute vec2 uv;
      uniform mat4 worldViewProjection;
      varying vec2 vUV;
      void main(void) {
          gl_Position = worldViewProjection * vec4(position, 1.0);
          vUV = uv;
      }
    `;
  }
  if( !Effect.ShadersStore[shaderNameFrag] ) {
    Effect.ShadersStore[shaderNameFrag] = `
      precision highp float;
      varying vec2 vUV;
      uniform sampler2D unlitTexture;
      void main(void) {
        vec4 color = texture2D(unlitTexture, vUV);
        gl_FragColor = color;
      }
    `;
  }
  const unlitTextureShaderMaterial = new ShaderMaterial("unlitTextureShaderMaterial", scene, {
    vertex: shaderName,
    fragment: shaderName,
  }, {
      attributes: ["position", "uv"],
      uniforms: ["worldViewProjection"],
      samplers: ["unlitTexture"],
      needAlphaBlending: true,
  });
  unlitTextureShaderMaterial.setTexture("unlitTexture", texture);
  return unlitTextureShaderMaterial;
}

//----------------------------------------------------------------
export function getUVBoundingBox( mesh: Mesh ): { minU: number, minV: number, maxU: number, maxV: number } {
  const uvs = mesh.getVerticesData(VertexBuffer.UVKind) || [];
  let minU = Number.POSITIVE_INFINITY;
  let minV = Number.POSITIVE_INFINITY;
  let maxU = Number.NEGATIVE_INFINITY;
  let maxV = Number.NEGATIVE_INFINITY;
  for (let i = 0; i < uvs.length; i += 2) {
    const u = uvs[i];
    const v = uvs[i + 1];
    if (u < minU) minU = u;
    if (v < minV) minV = v;
    if (u > maxU) maxU = u;
    if (v > maxV) maxV = v;
  }
  return { minU, minV, maxU, maxV };
}

//----------------------------------------------------------------
export function projectToScreenSpace(engine:Engine, camera:Camera, position3D:Vector3):Vector2 {
  const worldMatrix = Matrix.Identity();
  const transformMatrix = getCameraTransformationMatrix( camera );
  const viewport = camera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight());
  const screenPos = Vector3.Project(
      position3D,
      worldMatrix,
      transformMatrix,
      viewport
  );
  return new Vector2(screenPos.x, screenPos.y);
}

//----------------------------------------------------------------
export function getCameraTransformationMatrix(camera:Camera):Matrix {
  const matrixView = getCameraViewMatrix(camera);
  const matrixProjection = camera.getProjectionMatrix().clone();
  const transformationMatrix = matrixView.multiply(matrixProjection);
  return transformationMatrix;
}

export function getCameraViewMatrix(camera:Camera):Matrix {
  const matrix = camera.getViewMatrix().clone();
  if( camera instanceof WebXRCamera ) { // fix for webxr.
    const matrixViewArray = new Float32Array(16);
    matrix.toArray(matrixViewArray, 0);
    convertMatrixCoordSystem( matrixViewArray );
    matrix.fromArray(matrixViewArray, 0);
  }
  return matrix;
}

export function getCameraPosition(camera:Camera):Vector3 {
  const worldMatrix = getCameraViewMatrix(camera).invert();
  const cameraPosition = Vector3.TransformCoordinates(Vector3.Zero(), worldMatrix);
  return cameraPosition;
}

export function getCameraForwardVector(camera:Camera):Vector3 {
  const worldMatrix = getCameraViewMatrix(camera).invert();
  const cameraForward = camera.getForwardRay(100, worldMatrix).direction;
  return cameraForward;
}

export function screenToRay(
  camera: Camera,
  screenPosition: Vector2,
  invertY: boolean
): Ray {
  // get actual window res
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const normalizedScreenX = ((screenPosition.x / (windowWidth * window.devicePixelRatio)) * 2) - 1;
  const normalizedScreenY = ((screenPosition.y / (windowHeight * window.devicePixelRatio)) * 2) - 1;

  // Create a vector in NDC
  const ndcNear = new Vector3(normalizedScreenX, normalizedScreenY * (invertY ? -1 : 1)  , 0); // Near plane
  const ndcFar = new Vector3(normalizedScreenX, normalizedScreenY * (invertY ? -1 : 1) , 1); // Far plane

  // Get the camera's transformation matrix
  const invViewProj = Matrix.Invert(getCameraTransformationMatrix(camera));

  // Unproject to world space
  const worldPosNear = Vector3.TransformCoordinates(ndcNear, invViewProj);
  const worldPosFar = Vector3.TransformCoordinates(ndcFar, invViewProj);

  // Compute ray direction
  const rayDirection = worldPosFar.subtract(worldPosNear).normalize();

  // Create and return the ray
  return new Ray(worldPosNear, rayDirection);
}

//---------------------------------------------------------------- LHS <-> RHS conversion.
export function convertMatrixCoordSystem(matrix:Float32Array) {
  matrix[0] *= -1;
  matrix[1];
  matrix[2] *= -1;
  matrix[3];
  matrix[4] *= -1;
  matrix[5];
  matrix[6] *= -1;
  matrix[7];
  matrix[8] *= -1;
  matrix[9];
  matrix[10] *= -1;
  matrix[11];
  matrix[12] *= -1;
  matrix[13];
  matrix[14] *= -1;
  matrix[15];
}
