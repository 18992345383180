import Utils from "../utils/utils";
import Bridge, { BridgeMessage } from "./bridge";
import { BridgeMessageTypeImmersalLoadMap, BridgeMessageTypeImmersalLocalizeOnCloud, BridgeMessageTypeImmersalLocalizeOnDevice } from "./bridge.types";

export default class BridgeImmersal extends Bridge {
  constructor() {
    super();
  }
    
  public async loadMapFromImmersalApi(token:string, mapId:string) {
    const path = "https://api.immersal.com/map?token=" + token + "&id=" + mapId;
    return this.loadMapFromPath( path );
  }
  
  public async loadMapFromPath(path:string) {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeImmersalLoadMap,
        data: {
          path: path,
        },
      };
      this.send(message, resolve, reject);
    });
  }
  
  public async localizeOnDevice() {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeImmersalLocalizeOnDevice,
        data: undefined,
      };
      this.send(message, resolve, reject);
    });
  }

  public async localizeOnCloud(token:string, mapIds:number[]) {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeImmersalLocalizeOnCloud,
        data: {
          token: token,
          mapIds: mapIds,
        },
      };
      this.send(message, resolve, reject);
    });
  }
}