/* eslint-disable @typescript-eslint/no-unused-vars */

import { Camera, Engine, Scene, TransformNode } from "babylonjs";
import { IAssetBase } from "../../apps/app.content";

export default class ContentBase {
  
  protected engine?: Engine;
  protected scene?: Scene;
  protected camera?: Camera;
  protected rootNode?: TransformNode;
  
  constructor() {
    //
  }

  public init( engine: Engine, scene: Scene, camera: Camera, rootNode?: TransformNode ): void {
    this.engine = engine;
    this.scene = scene;
    this.camera = camera;
    this.rootNode = rootNode;
  }

  public get assets(): IAssetBase[] {
    return [];
  }

  public loadAssetComplete( _asset: IAssetBase ): void {
    // override in subclass.
  }

  public render(): void {
    // override in subclass.
  }

  public resize(): void {
    // override in subclass.
  }
}
