import { AbstractMesh, Matrix, Mesh, PBRMaterial, Quaternion, Vector3 } from "babylonjs";
import PoseProcessor from "./../core/pose/PoseProcessor";
import BridgeImmersal from "../core/bridge/bridge.immersal";
import { EJApp } from "../core/utils/utils";
import ImmersalBase from "../core/immersal/immersal.base";
import ImmersalAppDevice from "../core/immersal/immersal.app.device";
import ImmersalWebDevice from "../core/immersal/immersal.web.device";
import ImmersalWebCloud from "../core/immersal/immersal.web.cloud";
import { CameraIntrinsics, LocalizeResult } from "../core/immersal/immersal.common";
import ImmersalAppCloud from "../core/immersal/immersal.app.cloud";
import AppContent, { IAssetBase, IAssetModel, IAssetPointCloud } from "./app.content";

//---------------------------------------------------------------- 
const BASE_URL = "https://developers.immersal.com/";
const DOWNLOAD_MAP = "map";
const DOWNLOAD_SPARSE = "sparse";
const DOWNLOAD_DENSE= "dense";
const DOWNLOAD_TEXTURED = "tex";

const ImmersalSparseMapIdPrefix = "immersalSparseMap";
const ImmersalDenseMapIdPrefix = "immersalDenseMap";
const ImmersalTexturedMapIdPrefix = "immersalTexturedMap";

//---------------------------------------------------------------- 
export function ImmersalSparseMapId( mapId: number ) {
  return `${ImmersalSparseMapIdPrefix}-${mapId}`;
}

export function ImmersalDenseMapId( mapId: number ) {
  return `${ImmersalDenseMapIdPrefix}-${mapId}`;
}

export function ImmersalTexturedMapId( mapId: number ) {
  return `${ImmersalTexturedMapIdPrefix}-${mapId}`;
}

//---------------------------------------------------------------- 
export interface IAppImmersalMapConfig {
  mapId: number;
  loadSparseMap?: boolean;
  loadDenseMap?: boolean;
  loadTexturedMesh?: boolean;
  binaryMapPath?: string;   // optional, used to load assets locally instead of immersal servers.
  sparseMapPath?: string;   // optional, used to load assets locally instead of immersal servers.
  denseMapPath?: string;    // optional, used to load assets locally instead of immersal servers.
  texturedMapPath?: string; // optional, used to load assets locally instead of immersal servers.
  pointCloudSize?: number;
  pointCloudColor?: [number, number, number];
  position?: [number, number, number];
  rotation?: [number, number, number];
  matrix?: Matrix;
}

export class AppImmersalMapConfig {

  public mapId = -1;
  public loadSparseMap = false;
  public loadDenseMap = false;
  public loadTexturedMesh = false;
  public binaryMapPath?: string;   // optional, used to load assets locally instead of immersal servers.
  public sparseMapPath?: string;   // optional, used to load assets locally instead of immersal servers.
  public denseMapPath?: string;    // optional, used to load assets locally instead of immersal servers.
  public texturedMapPath?: string; // optional, used to load assets locally instead of immersal servers.
  public pointCloudSize = 10; // default.
  public pointCloudColor?: [number, number, number];
  public position?: [number, number, number];
  public rotation?: [number, number, number];
  public matrix?: Matrix;

  constructor( config: IAppImmersalMapConfig ) {
    this.mapId = config.mapId;
    //
    this.loadSparseMap = config.loadSparseMap ?? this.loadSparseMap;
    this.loadDenseMap = config.loadDenseMap ?? this.loadDenseMap;
    this.loadTexturedMesh = config.loadTexturedMesh ?? this.loadTexturedMesh;
    //
    this.binaryMapPath = config.binaryMapPath ?? this.binaryMapPath;
    this.sparseMapPath = config.sparseMapPath ?? this.sparseMapPath;
    this.denseMapPath = config.denseMapPath ?? this.denseMapPath;
    this.texturedMapPath = config.texturedMapPath ?? this.texturedMapPath;
    //
    this.pointCloudSize = config.pointCloudSize ?? this.pointCloudSize;
    this.pointCloudColor = config.pointCloudColor ?? this.pointCloudColor;
    //
    this.position = config.position ?? this.position;
    this.rotation = config.rotation ?? this.rotation;
    if( this.position && this.rotation ) {
      this.matrix = Matrix.Compose(
        new Vector3( 1, 1, 1 ),
        Quaternion.FromEulerAngles( this.rotation[0], this.rotation[1], this.rotation[2] ),
        new Vector3( this.position[0], this.position[1], this.position[2] ),
      );
    }
  }
}

//---------------------------------------------------------------- 
export interface IAppImmersalConfig {
  token: string;
  localizeOnDevice?: boolean;  // optional, localiza on device or cloud. cloud localization is default.
  usePoseProcessor?: boolean;  // optional, used to process poses.
  maps?: IAppImmersalMapConfig[];
}

export class AppImmersalConfig {
  public token = "";
  public localizeOnDevice = false;
  public usePoseProcessor = false;  // optional, used to process poses.
  public maps: AppImmersalMapConfig[] = [];

  public constructor(config: IAppImmersalConfig) {
    this.token = config.token;
    this.localizeOnDevice = config.localizeOnDevice ?? this.localizeOnDevice;
    this.usePoseProcessor = config.usePoseProcessor ?? this.usePoseProcessor;
    for( const mapConfig of config.maps ?? [] ) {
      const map = new AppImmersalMapConfig( mapConfig );
      const pathSuffix = "?token=" + this.token + "&id=" + map.mapId;
      map.binaryMapPath = map.binaryMapPath ?? (BASE_URL + DOWNLOAD_MAP + pathSuffix);
      map.sparseMapPath = map.sparseMapPath ?? (BASE_URL + DOWNLOAD_SPARSE + pathSuffix);
      map.denseMapPath = map.denseMapPath ?? (BASE_URL + DOWNLOAD_DENSE + pathSuffix);
      map.texturedMapPath = map.texturedMapPath ?? (BASE_URL + DOWNLOAD_TEXTURED + pathSuffix);
      this.maps.push( map );
    }
  }
}

//---------------------------------------------------------------- 
export default class AppImmersal extends AppContent {

  protected immersalImpl?: ImmersalBase;
  protected localizing = false;

  protected immersalConfig?: AppImmersalConfig;
  protected poseProcessor?: PoseProcessor;
  protected cameraMat?: Matrix;
  protected bridge?: BridgeImmersal;

  public constructor(renderCanvas: HTMLCanvasElement) {
    super(renderCanvas);
  }

  public setConfigImmersal( immersalConfig: AppImmersalConfig ) {
    this.immersalConfig = immersalConfig;
  }

  protected override initialize() {
    super.initialize();
    //
    this.cameraMat = new Matrix();
    //
    if( !this.immersalConfig ) {
      console.error( "app.immersal.initialize error: immersalConfig is undefined" );
      return;
    }
    const mapIds = this.immersalConfig.maps.map( (mapConfig) => { return mapConfig.mapId } );
    if( EJApp ) {
      if( this.immersalConfig.localizeOnDevice ) {
        this.immersalImpl = new ImmersalAppDevice( this.immersalConfig.token, mapIds );
      } else {
        this.immersalImpl = new ImmersalAppCloud( this.immersalConfig.token, mapIds );
      }
    } else {
      if( this.immersalConfig.localizeOnDevice ) {
        this.immersalImpl = new ImmersalWebDevice( this.immersalConfig.token, mapIds );
      } else {
        this.immersalImpl = new ImmersalWebCloud( this.immersalConfig.token, mapIds );
      }
    }
    //
    if( this.immersalConfig.usePoseProcessor ) {
      this.poseProcessor = new PoseProcessor();
    }
  }

  public override async loadAsync() {
    const promises = [
      this.loadImmersalBinaryMaps(),
      this.loadImmersalSparseMaps(),
      this.loadImmersalDenseMaps(),
      this.loadImmersalTexturedMaps(),
    ];
    await Promise.all(promises); // load all immersal assets first.
  }

  private async loadImmersalBinaryMaps(): Promise<void> {
    await this.immersalImpl!.init(); // immersal.web.device needs to load the immersal wasm libraries before loading the map.
    //
    const maps = (this.immersalConfig!.maps).slice(0, 1); // TODO: check if device localization supports more then one map.
    for( const mapConfig of maps ) {
      await this.immersalImpl!.loadMap( mapConfig.binaryMapPath! );
    }
  }

  private async loadImmersalSparseMaps(): Promise<void> {
    for( const mapConfig of this.immersalConfig?.maps ?? [] ) {
      if( !mapConfig.loadSparseMap || !mapConfig.sparseMapPath ) {
        continue;
      }
      const asset: IAssetPointCloud = {
        id: ImmersalSparseMapId( mapConfig.mapId ),
        type: "pointcloud",
        path: mapConfig.sparseMapPath,
        size: mapConfig.pointCloudSize,
        color: mapConfig.pointCloudColor,
      }
      await this.loadAsset( asset );
    }
  }

  private async loadImmersalDenseMaps(): Promise<void> {
    for( const mapConfig of this.immersalConfig?.maps ?? [] ) {
      if( !mapConfig.loadDenseMap || !mapConfig.denseMapPath ) {
        continue;
      }
      const asset: IAssetPointCloud = {
        id: ImmersalDenseMapId( mapConfig.mapId ),
        type: "pointcloud",
        path: mapConfig.denseMapPath,
        size: mapConfig.pointCloudSize,
        color: mapConfig.pointCloudColor,
      }
      await this.loadAsset( asset );
    }
  }

  private async loadImmersalTexturedMaps(): Promise<void> {
    for( const mapConfig of this.immersalConfig?.maps ?? [] ) {
      if( !mapConfig.loadTexturedMesh || !mapConfig.texturedMapPath ) {
        continue;
      }
      const asset: IAssetModel = {
        id: ImmersalTexturedMapId( mapConfig.mapId ),
        type: "model",
        path: mapConfig.texturedMapPath,
      }
        await this.loadAsset( asset );
    }
  }

  protected override loadAssetComplete( asset: IAssetBase ) {
    if( !asset.id ) {
      return;
    }
    const isImmersalSparseMap = asset.id.startsWith( ImmersalSparseMapIdPrefix );
    const isImmersalDenseMap = asset.id.startsWith( ImmersalDenseMapIdPrefix );
    const isImmersalTexturedMap = asset.id.startsWith( ImmersalTexturedMapIdPrefix );
    const isImmersalAsset = isImmersalSparseMap || isImmersalDenseMap || isImmersalTexturedMap;
    let mapMat: Matrix | undefined = undefined;
    if( isImmersalAsset ) {
      const mapIdStr = asset.id.split('-')[1];
      const mapId = mapIdStr ? parseInt(mapIdStr) : -1;
      const mapConfig = this.immersalConfig?.maps.find((mapConfig) => mapConfig.mapId === mapId);
      if( mapConfig && mapConfig.matrix ) {
        mapMat = mapConfig.matrix;
      }
    }
    if( isImmersalSparseMap || isImmersalDenseMap ) {
      const assetPointCloud = asset as IAssetPointCloud;
      if( assetPointCloud.mesh && mapMat ) {
        this.transformImmersalMesh( assetPointCloud.mesh, mapMat );
      }
    } else if( isImmersalTexturedMap ) {
      const assetModel = asset as IAssetModel;
      if( assetModel.meshRoot && mapMat ) {
        this.transformImmersalMesh( assetModel.meshRoot, mapMat );
      }
      for (const abstractMesh of assetModel.meshes ?? []) {
        const mesh = abstractMesh as Mesh;
        if (mesh.material && mesh.material instanceof PBRMaterial) {
          const pbrMaterial = mesh.material as PBRMaterial;
          pbrMaterial.unlit = true;
        }
      }
    }
  }

  protected transformImmersalMesh( mesh: AbstractMesh, matrix: Matrix ) {
    if( !mesh.rotationQuaternion ) {
      mesh.rotationQuaternion = new Quaternion();
    }
    matrix.decompose(
      mesh.scaling,
      mesh.rotationQuaternion,
      mesh.position 
    );
  }

  protected override render() {
    super.render();

    if( this.poseProcessor ) {
      this.poseProcessor.update( this.timeDelta );
      const mat = this.poseProcessor.getPoseMatrix();
      this.transformContent2( mat );
    }
  }

  public async localize(imageData?: Uint8ClampedArray, imageWidth?: number, imageHeight?: number, intrinsics?: CameraIntrinsics): Promise<LocalizeResult> {
    return new Promise<LocalizeResult>((resolve, reject) => {
      if( this.localizing ) {
        reject(new Error('AppImmersal.localize: already localizing.'));
        return;
      }
      this.immersalImpl!.localize(imageData, imageWidth, imageHeight, intrinsics).then((res:LocalizeResult) => {
        this.localizing = false;
        if( res.success ) {
          const immersalMat = Matrix.FromArray(res.matrix!);
          //
          let mapMat: Matrix | undefined = undefined;
          const mapConfig = this.immersalConfig!.maps.find((mapConfig) => mapConfig.mapId === res.map);
          if( mapConfig && mapConfig.matrix ) {
            mapMat = mapConfig.matrix.clone();
          }
          this.transformContent( immersalMat, mapMat );
        }
        resolve(res);
      }).catch((err:any) => {
        this.localizing = false;
        reject(err);
      });
    });
  }

  private transformContent = (immersalMat: Matrix, mapMat?: Matrix) => {
    let mat: Matrix | undefined = undefined;
    if(mapMat) {
      mat = mapMat.invert().multiply(immersalMat.invert()).multiply(this.cameraMat!);
    } else {
      mat = immersalMat.invert().multiply(this.cameraMat!);
    }
    if(this.poseProcessor) {
      this.poseProcessor.setData(mat);
    } else {
      this.transformContent2( mat );
    }
  }

  private transformContent2 = (mat: Matrix) => {
    mat.decomposeToTransformNode(this.rootNode!);
  }
}