import ContentLincolnCenterModelVideo from "./content.lincolncenter.modelvideo";
import { CatDance } from "./content.lincolncenter.types";
import ModelPath from '@lincolncenter/catdance/catdance-model.glb';
import VideoPath from '@lincolncenter/catdance/catdance-video.mp4';
import { IAssetBase } from "@/apps/app.content";

export default class ContentLincolnCenterCatDance extends ContentLincolnCenterModelVideo {
    constructor() {
        super();
        this.id = CatDance;
        this.modelPath = ModelPath;
        this.videoPath = VideoPath;
        this.videoMaterialName = 'Video';
    }

    public override loadAssetComplete(asset: IAssetBase): void {
        super.loadAssetComplete(asset);
        
    }
}