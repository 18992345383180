import ShareBase from "./share.base";
import { ShareResponse } from "./share.types";

export default class ShareWeb extends ShareBase {
  
  constructor() {
    super();
  }

  public override async share( data:ShareData ): Promise<ShareResponse> {
    try {
      if( !navigator.share ) {
        return { completed: false, error: 'Web Share API not available' };
      }
      await navigator.share(data);
      return { completed: true };
    } catch (error) {
      if (error instanceof Error) {
        return { completed: false, error: error.message };
      } else {
        return { completed: false, error: String(error) };
      }
    }
  }
}