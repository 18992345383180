import { EJApp } from "../utils/utils";
import LocalStorageApp from "./localstorage.app";
import LocalStorageBase from "./localstorage.base";
import LocalStorageWeb from "./localstorage.web";

export default class LocalStorage extends LocalStorageBase {
  private impl: LocalStorageBase;
  
  constructor() {
    super();

    if( EJApp ) {
      this.impl = new LocalStorageApp();
    } else {
      this.impl = new LocalStorageWeb();
    }    
  }

  public override async setItemAsync(key: string, value: string): Promise<void> {
    return this.impl.setItemAsync(key, value);
  }

  public override async getItemAsync(key: string): Promise<string | null> {
    return this.impl.getItemAsync(key);
  }

  public override async removeItemAsync(key: string): Promise<void> {
    return this.impl.removeItemAsync(key);
  }

  public override async clearAsync(): Promise<void> {
    return this.impl.clearAsync();
  }
}