export const Plaza = 'plaza';
export const GovIsland = 'govisland';
export const RoofTop = 'rooftop';
export const Annabelle = 'annabelle';
export const Ballet = 'ballet';
export const Idrani = 'idrani';
export const TapHappenings = 'taphappenings';
export const Breakers = 'breakers';
export const CatDance = 'catdance';
export const Wayfinding = 'wayfinding-path';
export const Spots = 'spots';
export const Occlusion = 'occlusion';
export const Calibration = 'calibration';