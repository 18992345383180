import { Matrix} from "babylonjs";
import PoseProcessFilter from "./PoseProcessFilter";
import PoseProcessSmooth from "./PoseProcessSmooth";
import PoseProcess from "./PoseProcess";

export default class PoseProcessor {

  private poseMatrix:Matrix = Matrix.Identity();
  private poseFilter:PoseProcess = new PoseProcessFilter();
  private poseSmooth:PoseProcess = new PoseProcessSmooth();

  constructor() {
    //
  }

  public setData(matrix:Matrix) {
    this.poseFilter.setData( matrix );
    this.poseFilter.getPoseMatrix( matrix );
    this.poseSmooth.setData( matrix );
  }

  public update(timeDelta: number) {
    this.poseSmooth.update(timeDelta);
    this.poseSmooth.getPoseMatrix( this.poseMatrix )
  }

  public getPoseMatrix() {
    return this.poseMatrix;
  }

  public reset() {
    this.poseFilter.reset();
    this.poseSmooth.reset();
  }
}