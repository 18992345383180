import { Matrix, Quaternion, Vector3 } from "babylonjs";

export default class PoseProcess {

  protected position = new Vector3(0, 0, 0);
  protected rotation = Quaternion.Identity();
  protected scale = new Vector3(1, 1, 1);

  constructor() {
    //
  }

  public setData(matrix:Matrix) {
    //
  }

  public update(timeDelta:number) {
    //
  }

  public reset() {
    this.position.set
    this.rotation.set(0, 0, 0, 1);
    this.scale.set(1, 1, 1);
  }

  public getPoseMatrix(matrix:Matrix) {
    Matrix.ComposeToRef(this.scale, this.rotation, this.position, matrix);
  }
}