import { Effect, Mesh, Scene, ShaderMaterial, Texture, TransformNode, Vector3, VertexData } from "babylonjs";

export default class ContentUIImage {
  protected _texture: Texture;
  protected _textureWidth: number = 0;
  protected _textureHeight: number = 0;
  protected _x: number = 0;
  protected _y: number = 0;
  protected _width: number = 0;
  protected _height: number = 0;
  protected _scaleX: number = 1;
  protected _scaleY: number = 1;
  protected _rotation: number = 0;
  protected _alpha: number = 1.0;
  protected _material: ShaderMaterial;
  protected _mesh: Mesh;
  protected _node: TransformNode;
  
  constructor( texture: Texture, scene: Scene ) {
    this._texture = texture;
    this._texture.hasAlpha = true;
    this._textureWidth = texture.getSize().width;
    this._textureHeight = texture.getSize().height;
    this._width = this._textureWidth
    this._height = this._textureHeight;
    this._node = new TransformNode("content-ui-node", scene);
    //
    Effect.ShadersStore["uiSimpleVertexShader"] = `
      precision highp float;

      // Attributes
      attribute vec2 position;

      // Varyings
      varying vec2 vUV;

      // take in world matrix
      uniform mat4 world;

      void main(void) {
        // Calculate UV
        vUV = position * 0.5 + 0.5;
        gl_Position = world * vec4(position, 0.0, 1.0);
      }
    `;
    Effect.ShadersStore["uiSimpleFragmentShader"] = `
    precision highp float;

    // Varying
    varying vec2 vUV;
    uniform sampler2D textureSampler;
    uniform float alphaV;

    void main(void) {

        // flip x-axis
        vec2 flippedUV = vec2(-vUV.x, vUV.y);

        vec4 col = texture2D(textureSampler, flippedUV);
        if (col.a < 0.01) discard;
        col.a = col.a * alphaV;
        gl_FragColor = col;
    }
    `;
    const shaderMat = new ShaderMaterial(
      "shader",
      scene,
      {
        vertex: "uiSimple",
        fragment: "uiSimple",
      },
      {
        attributes: ["position"],
        uniforms: ["world", "alphaV"],
      }
    );
    // Define vertex positions in NDC
    const positions = [
      -1,
      -1,
      0, // Bottom-left
      1,
      -1,
      0, // Bottom-right
      1,
      1,
      0, // Top-right
      -1,
      1,
      0, // Top-left
    ];
    // Define indices for two triangles
    const indices = [
      0,
      1,
      2, // First triangle
      0,
      2,
      3, // Second triangle
    ];
    // Define UV coordinates
    const uvs = [
      0,
      0, // Bottom-left
      1,
      0, // Bottom-right
      1,
      1, // Top-right
      0,
      1, // Top-left
    ];
    // Create a custom mesh
    this._mesh = new Mesh("ui", scene);
    this._mesh.renderingGroupId =  2;
    this._mesh.alwaysSelectAsActiveMesh = true;
    this._mesh.isPickable = false;
    this._mesh.parent = this._node;
    this._mesh.position = new Vector3(0, 0, 0);

    shaderMat.backFaceCulling = false;
    shaderMat.zOffset = -100;
    shaderMat.disableDepthWrite = true;
    shaderMat.setTexture("textureSampler", this._texture);
    
    // enable alpha blending
    shaderMat.alphaMode = BABYLON.Constants.ALPHA_COMBINE;
    shaderMat.needAlphaBlending = () => true;
    
    this._mesh.material = shaderMat;
    this._material = shaderMat;

    // Create and apply vertex data
    const vertexData = new VertexData();
    vertexData.positions = positions;
    vertexData.indices = indices;
    vertexData.uvs = uvs;
    vertexData.applyToMesh(this._mesh);
  }

  public switchTexture(texture: Texture) {
    this._texture = texture;
    this._texture.hasAlpha = true;
    this._textureWidth = texture.getSize().width;
    this._textureHeight = texture.getSize().height;
    this._width = this._textureWidth
    this._height = this._textureHeight;
    this._material.setTexture("textureSampler", this._texture);
  }

  public update(screenWidth:number, screenHeight:number) {
    const scaleX = this._width / (screenWidth );
    const scaleY = this._height / (screenHeight );
    const positionX = (this._x - screenWidth * 0.5) / screenWidth * 2;
    const positionY = (this._y - screenHeight * 0.5) / screenHeight * -2;
    this._node.scaling = new Vector3(scaleX, scaleY , 1.0);
    this._node.position = new Vector3(positionX, positionY, 0);
    this._mesh.rotation.z = -this._rotation; // position / scale / rotation applied across mesh and node to get around skewing issue.

    const world = this._mesh.getWorldMatrix()

    if (this._material) {
      this._material.setMatrix("world", world);
      this._material.setFloat("alphaV", this._alpha);
    }
  }

  public get mesh(): Mesh { return this._mesh; }

  public get textureWidth(): number { return this._textureWidth; }
  public get textureHeight(): number { return this._textureHeight; }

  public set x( value: number ) { this._x = value; }
  public get x(): number { return this._x; }

  public set y( value: number ) { this._y = value; }
  public get y(): number { return this._y; }

  public set width( value: number ) { this._width = value; }
  public get width(): number { return this._width; }

  public set height( value: number ) { this._height = value; }
  public get height(): number { return this._height; }

  public set scaleX( value: number ) { 
    this._scaleX = value; 
    this._width = this._textureWidth * this._scaleX;
  }
  public get scaleX(): number { return this._scaleX; }

  public set scaleY( value: number ) { 
    this._scaleY = value; 
    this._height = this._textureHeight * this._scaleY;
  }
  public get scaleY(): number { return this._scaleY; }

  public set rotation( value: number ) { this._rotation = value; }
  public get rotation(): number { return this._rotation; }

  public set alpha( value: number ) { this._alpha = value; }
  public get alpha(): number { return this._alpha; }
}