/* eslint-disable @typescript-eslint/no-unused-vars */

import { ShareResponse } from "./share.types";

export default class ShareBase {
  constructor() {
    //
  }

  public async share( _data:ShareData ): Promise<ShareResponse> {
    return Promise.reject(new Error("share.base.share must be overridden in the subclass"));
  }
}