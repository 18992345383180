import { useContext } from "preact/hooks";
import { AppBaseContext } from "../context/app-context";

export const useApp = () => {
    const context = useContext(AppBaseContext);
    if (!context) {
      throw new Error('useApp must be used within an AppBaseProvider');
    }
    return context;
  };
