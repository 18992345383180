import { HemisphericLight, Tools, UniversalCamera, Vector3 } from 'babylonjs';
import { LocalizeResult } from '../core/immersal/immersal.common';
import AppContent from './app.content';

export class AppSim3dConfig {
  public cameraInitialPosition: Vector3 = new Vector3(0, 0, 0);
  public cameraInitialRotationDegrees: number = 0;
  public cameraLockedToHeight?: number;
}

export default class AppSim3d extends AppContent {

  private configSim3d: AppSim3dConfig = new AppSim3dConfig();

  constructor() {
    const renderCanvas = <HTMLCanvasElement>document.getElementById('renderCanvas');
    super(renderCanvas);
  }

  public setConfigSim3d( configSim3d: AppSim3dConfig ) {
    this.configSim3d = configSim3d;
  }

  protected override initialize() {
    super.initialize();
      
    const light = new HemisphericLight("light", new Vector3(0, 1, 0), this.scene);
    light.intensity = 0.7;
  }

  protected override initScene(): void {
    super.initScene();
    this.scene!.autoClear = true;
  }

  protected override initCamera(): void {
    const camera = new UniversalCamera("camera", new Vector3(0, 0, 0), this.scene);
    camera.attachControl(this.renderCanvas, true);

    camera.keysUp.push(87);    // W key
    camera.keysDown.push(83);  // S key
    camera.keysLeft.push(65);  // A keys
    camera.keysRight.push(68); // D key 

    camera.speed = 0.5;               // Movement speed
    camera.inertia = 0.9;             // Smoothness of camera movements
    camera.angularSensibility = 5000; // Mouse sensitivity
    camera.position.copyFrom(this.configSim3d.cameraInitialPosition);
    camera.rotation.y = Tools.ToRadians( this.configSim3d.cameraInitialRotationDegrees );

    if( this.configSim3d.cameraLockedToHeight ) {
      this.scene!.onBeforeRenderObservable.add(() => {
        camera!.position.y = this.configSim3d.cameraLockedToHeight!;
      });
    }

    this.camera = camera;
  }

  public override async startXR() {
    // override to stop going to subclass.
  }

  public override async stopXR() {
    // override to stop going to subclass.
  }

  public override async localizeAsync(): Promise<LocalizeResult> {
    const result: LocalizeResult = {
      success: false,
      confidence: 0,
    }
    return result; // return false result as AppSim3d does not support localization.
  }
}