import ContentLincolnCenterModelVideo from "./content.lincolncenter.modelvideo";
import { TapHappenings } from "./content.lincolncenter.types";
import Model from "@lincolncenter/taphappenings/tap-happenings.glb";
import Video from "@lincolncenter/taphappenings/tap-happenings.mp4";

export class ContentLincolnCenterTapHappenings extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = TapHappenings;
    this.checkVideoSynchronization = false;

    this.modelPath = Model;
    this.videoPath = Video;
    this.videoMaterialName = "videoTexture";

  }
}