/* eslint-disable @typescript-eslint/no-unused-vars */

export default class LocalStorageBase {
  constructor() {
    //
  }

  public async setItemAsync(_key: string, _value: string): Promise<void> {
    return Promise.reject(new Error("localstorage.base.setItemAsync must be overridden in the subclass"));
  }

  public async getItemAsync(_key: string): Promise<string | null> {
    return Promise.reject(new Error("localstorage.base.getItemAsync must be overridden in the subclass"));
  }

  public async removeItemAsync(_key: string): Promise<void> {
    return Promise.reject(new Error("localstorage.base.removeItemAsync must be overridden in the subclass"));
  }

  public async clearAsync(): Promise<void> {
    return Promise.reject(new Error("localstorage.base.clearAsync must be overridden in the subclass"));
  }
}