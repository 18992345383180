import LocalStorage from '@/core/localstorage/localstorage';
import { useEffect, useState } from 'react';

const localStorage = new LocalStorage();

function useLocalStorage<T>(key: string, initialValue:T | null) {
  const [storedValue, setStoredValue] = useState<T | null>(initialValue);

  useEffect(() => {
    const fetchLocalStorage = async () => {
      try {
          const item = await localStorage.getItemAsync(key);
          if (item !== null) {
            const isObject = typeof initialValue === 'object' && initialValue !== null;
            const parsedItem = isObject ? JSON.parse(item) : item;
            setStoredValue(parsedItem as T);
          } else {
            setStoredValue(initialValue);
          }
      } catch (error) {
        console.error('Error fetching from localStorage:', error);
        setStoredValue(initialValue);
      }
    };

    fetchLocalStorage();
  }, [key]);

  const setValue = async (value: T | ((val: T | null) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      await localStorage.setItemAsync(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error setting value to localStorage:', error);
    }
  };

  const clearValue = async (key: string) => {
    try {
      await localStorage.removeItemAsync(key);
    } catch (error) {
      console.error('Error clearing localStorage:', error);
    }
  };

  return [storedValue, setValue, clearValue] as const;
}

export default useLocalStorage;