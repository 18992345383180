/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
  interface Window {
    ejx?: {
      response?: (data: any) => void;
    };
    webkit?: {
      messageHandlers?: {
        eyejack?: {
          postMessage: (data: any) => void;
        };
      };
    };
    ARCore?: {
      eyejackMessage?: (data: any) => void;
    };
  }
}

export interface BridgeMessage {
  "uuid": string;
  "type": string;
  "data": any | undefined;
}

export interface BridgeResponse {
  "uuid": string;
  "data": any | undefined;
}

export interface BridgeCallback {
  "message": BridgeMessage;
  "resolve": (data: any) => void | undefined,
  "reject": (error: any) => void | undefined,
}

export interface BridgeSub {
  "uuid": string;
  "func": (data?: any) => void | undefined;
}

export default class Bridge {
  private callbacks: BridgeCallback[] = [];
  private subs: BridgeSub[] = [];
  
  private static instances: Bridge[] = [];
  private static ResponseCallback = (data: any) => {
    Bridge.instances.forEach((instance) => {
      instance.response(data);
    });
  }
  
  constructor() {
    window.ejx = window.ejx || {};
    window.ejx.response = window.ejx.response || Bridge.ResponseCallback;
    Bridge.instances.push(this);
  }

  public dispose() {
    const index = Bridge.instances.indexOf(this);
    if (index !== -1) {
      Bridge.instances.splice(index, 1);
    }
  }

  protected send(message:BridgeMessage, resolve:any, reject:any) {
    const callback: BridgeCallback = {
      message: message,
      resolve: resolve,
      reject: reject,
    };
    this.callbacks.push(callback);
    //
    let sent = true;
    if( window.webkit?.messageHandlers?.eyejack ) { 
      window.webkit?.messageHandlers?.eyejack?.postMessage(message); // try sending to iOS.
    } else if( window.ARCore?.eyejackMessage ) { 
      window.ARCore?.eyejackMessage?.(message); // try sending to Android.
    } else {
      sent = false;
    }
    if( !sent ) {
      console.error('Bridge.send failed.');
      this.callbacks.pop(); // remove last callback which failed.
    }
  }

  public subscribe(uuid:string, func:(data?:any) => void) {
    const subIndex = this.subs.findIndex((sub) => sub.uuid === uuid);
    if( subIndex === -1 ) {
      const sub = {
        uuid: uuid,
        func: func,
      }
      this.subs.push(sub);
    }
  }

  public unsubscribe(uuid:string) {
    const subIndex = this.subs.findIndex((sub) => sub.uuid === uuid);
    if( subIndex !== -1 ) {
      this.subs.splice(subIndex, 1);
    }
  }

  public response(message:BridgeResponse) {
    const callbackIndex = this.callbacks.findIndex((callback) => callback.message.uuid === message.uuid);
    if( callbackIndex !== -1 ) {
      const callback: BridgeCallback = this.callbacks[callbackIndex];
      this.callbacks.splice(callbackIndex, 1); // remove from the list so it can't be called again.
      if( message ) {
        callback.resolve( message.data );
      } else {
        callback.reject(new Error("Bridge.response error - callback response returned undefined."));
      }
    }
    const subIndex = this.subs.findIndex((sub) => sub.uuid === message.uuid);
    if( subIndex !== -1 ) {
      const sub: BridgeSub = this.subs[subIndex];
      if( message ) {
        sub.func( message.data );
      } else {
        console.error("Bridge.response error - subscription response returned undefined.");
      }
    }
  }  
}