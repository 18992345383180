import { useEffect, useState } from 'react';
import { EJApp } from '@/core/utils/utils';

export type TDevice =
  | 'iOS'
  | 'Mac OS'
  | 'Windows'
  | 'Android'
  | 'Linux'
  | 'AppClip';

const extractIOSVersion = (userAgent: string): number | null => {
  const match = userAgent.match(/OS (\d+)_?(\d+)?_?(\d+)?/);
  return match ? parseInt(match[1]) : null;
};

const useUserDevice = () => {
  const [device, setDevice] = useState<TDevice>();
  const [isMobile, setIsMobile] = useState(false);
  const [iOSVersion, setIOSVersion] = useState<number | null>(null);

  useEffect(() => {
    const { userAgent } = navigator;

    const platform = window.navigator.platform;

    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (EJApp) {
      setDevice('AppClip');
      setIsMobile(true);
    } else if (macosPlatforms.includes(platform)) {
      if (
        platform === 'MacIntel' &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
      ) {
        setDevice('iOS');
        setIsMobile(true);
        const major = extractIOSVersion(userAgent);
        if (major !== null) setIOSVersion(major);
      } else {
        setDevice('Mac OS');
        setIsMobile(false);
      }
    } else if (iosPlatforms.includes(platform)) {
      setDevice('iOS');
      setIsMobile(true);
      const major = extractIOSVersion(userAgent);
      if (major !== null) setIOSVersion(major);
    } else if (windowsPlatforms.includes(platform)) {
      setDevice('Windows');
      setIsMobile(false);
    } else if (/Android/.test(userAgent)) {
      setDevice('Android');
      setIsMobile(true);
    } else if (!device && /Linux/.test(platform)) {
      setDevice('Linux');
      setIsMobile(false);
    }

  }, [device]);

  return {
    device,
    isMobile,
    iOSVersion,
  };
};

export default useUserDevice;
