export enum AppState {
  SPLASH = 'SPLASH',
  LOADING = 'LOADING',
  PERMISSIONS_HANDLING = 'PERMISSIONS_HANDLING',
  CALIBRATING = 'CALIBRATING',
  TRANSITIONING_XR = 'TRANSITIONING_XR',
  LOCATION_MODAL = 'LOCATION_MODAL',
  ONBOARDING = 'ONBOARDING',
  CALIBRATED = 'CALIBRATED',
  ARTWORK_VIEWING = 'ARTWORK_VIEWING',
  RECORDING = 'RECORDING',
  MEDIA_SHARE = 'MEDIA_SHARE'
}
