import { createContext, useState } from 'react';
import { AppState } from '../types/AppState';
import { RecordingState } from '../types/RecordingState';
export type AppBaseContext = {
  appState: AppState,
  setAppState: (state: AppState) => void;
  recordingState: RecordingState,
  setRecordingState: (state: RecordingState) => void;
}

export const AppBaseContext = createContext<AppBaseContext | null>(null);

export type AppBaseProviderProps = {
  children: React.ReactNode;
}
export const AppBaseProvider = ({ children }: AppBaseProviderProps) => {
  const [appState, setAppState] = useState<AppState>(AppState.SPLASH);
  const [recordingState, setRecordingState] = useState<RecordingState>(RecordingState.NONE);


  return (
    <AppBaseContext.Provider value={{ appState, setAppState, recordingState, setRecordingState }}>
      {children}
    </AppBaseContext.Provider>
  );
};


