import ContentBase from "../core/content.base";
import emitter from '../../core/events/eventemitter';

//----------------------------------------------------------------
export enum State {
  Unload,
  Loading,
  Loaded,
  Ready,
  View,
  Play,
}

//----------------------------------------------------------------
export default class ContentLincolnCenterBase extends ContentBase {
  
  public id: string = '';
  protected _state: State = State.Unload;
  
  constructor() {
    super();
  }

  //--------------------------------------------------------------
  public setStateUnload(): void {
    this._state = State.Unload;
    emitter.emit('content-unload', this.id);
  }

  public setStateLoading(): void {
    this._state = State.Loading;
    emitter.emit('content-loading', this.id);
  }

  public setStateLoaded():void {
    this._state = State.Loaded;
    emitter.emit('content-loaded', this.id);
  }

  public setStateReady():void {
    this._state = State.Ready;
    emitter.emit('content-ready', this.id);
  }

  public setStateView():void {
    this._state = State.View;
    emitter.emit('content-view', this.id);
  }

  public setStatePlay():void {
    this._state = State.Play;
    emitter.emit('content-play', this.id);
  }

  //--------------------------------------------------------------
  public get isStateUnload():boolean {
    return this._state === State.Unload;
  }

  public get isStateLoading():boolean {
    return this._state === State.Loading;
  }
  
  public get isStateLoaded(): boolean {
    return this._state === State.Loaded;
  }

  public get isStateReady(): boolean {
    return this._state === State.Ready;
  }

  public get isStateView(): boolean {
    return this._state === State.View;
  }

  public get isStatePlay(): boolean {
    return this._state === State.Play;
  }

  //--------------------------------------------------------------
  public override render(): void {
    //
  }
}