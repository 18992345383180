import { Constants, Mesh, Scene, Vector4, VideoTexture } from "babylonjs";
import { getUVBoundingBox, initAlphaVideoShaderMaterial } from "../core/content.common";

export function applyAlphaVideoToMesh( mesh:Mesh, videoTexture:VideoTexture, scene:Scene ): void {
  const uvBoxRange = getUVBoundingBox( mesh ); // uvBox for original uv coords.
  const uvBoxColor = { // adjusted uvBox to match uv coords for color in vidueo texture.
    minU: uvBoxRange.minU * 0.5,
    maxU: uvBoxRange.maxU * 0.5,
    minV: 1.0 - uvBoxRange.minV,
    maxV: 1.0 - uvBoxRange.maxV,
  }
  const uvBoxAlpha = { // adjusted uvBox to match uv coords for alpha in vidueo texture.
    minU: uvBoxColor.minU + 0.5,
    maxU: uvBoxColor.maxU + 0.5,
    minV: uvBoxColor.minV,
    maxV: uvBoxColor.maxV,
  }
  const uvRange = new Vector4( uvBoxRange.minU, uvBoxRange.minV, uvBoxRange.maxU, uvBoxRange.maxV );
  const uvColor = new Vector4( uvBoxColor.minU, uvBoxColor.minV, uvBoxColor.maxU, uvBoxColor.maxV );
  const uvAlpha = new Vector4( uvBoxAlpha.minU, uvBoxAlpha.minV, uvBoxAlpha.maxU, uvBoxAlpha.maxV );
  //
  const material = initAlphaVideoShaderMaterial( scene, videoTexture, uvRange, uvColor, uvAlpha );
  material.alphaMode = Constants.ALPHA_PREMULTIPLIED;
  material.backFaceCulling = false;
  mesh.material = material;
}