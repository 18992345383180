import ModelPath from '@lincolncenter/govisland/govisland-model.glb';
import VideoPath from '@lincolncenter/govisland/govisland-video-tex.mp4';
//
import ContentLincolnCenterModelVideo from './content.lincolncenter.modelvideo';
import { GovIsland } from './content.lincolncenter.types';

export default class ContentLincolnCenterGovIsland extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = GovIsland;
    this.modelPath = ModelPath;
    this.videoPath = VideoPath;
    this.videoMaterialName = 'allinone';
  }
}