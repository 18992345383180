import ModelPath from '@lincolncenter/rooftop/rooftop-model.glb';
import VideoPath from '@lincolncenter/rooftop/rooftop-video.mp4';
//
import ContentLincolnCenterModelVideo from './content.lincolncenter.modelvideo';
import { RoofTop } from './content.lincolncenter.types';

export default class ContentLincolnCenterRoofTop extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = RoofTop;
    this.modelPath = ModelPath;
    this.videoPath = VideoPath;
    this.videoMaterialName = 'rooftop videoBACK';
  }
}