import './style.scss';
import { render } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import clsx from 'clsx';
import emitter from '@/core/events/eventemitter';

// global ui 
import DebugMenu from '@/ui/components/Generics/DebugMenu';
import { Button } from '@/ui/components/Elements/Button';
import { FadeTransition } from '@/ui/components/Transitions/index.ts';

import { useApp } from '@/ui/hooks/useApp.ts';
import { useArtwork } from '@/ui/hooks/useArtwork.ts';
import { ArtworkProvider } from '@/ui/context/artwork-context';
import useLocalization from '@/ui/hooks/useLocalization';
import useUserId from '@/ui/hooks/useUserId';
import useAnalytics from '@/ui/hooks/useAnalytics';
import useUserDevice from '@/ui/hooks/useUserDevice.ts';

import { AppState } from '@/ui/types/AppState.ts';

import { AppBaseProvider } from "@/ui/context/app-context";

// custom ui
import { XRCheckOverlay } from './components/Layout/XRCheckOverlay';
import { Splash } from './components/Layout/index.ts';

emitter.on('initialized', () => {
    console.log('UI: app initialized')
})

export function App() {
    const [localizedSuccess, setLocalizedSuccess] = useState(0)
    const [totalLocalizedCount, setTotalLocalizedCount] = useState(0)

    const { appState, setAppState, recordingState } = useApp();
    const { currentArtwork, setCurrentArtwork } = useArtwork();
    const { localizeSuccess, localize, isLocalizing, localizeDuration } = useLocalization();

    const { device, isMobile } = useUserDevice();
    const userId = useUserId();

    const { initAnalytics, sendEvent } = useAnalytics(userId!);

    useEffect(() => {
        if (appState === 'CALIBRATING' && !isLocalizing) {
            localize();
        }
        if (localizeSuccess) {
            setAppState(AppState.CALIBRATED);
        }

    }, [localizeSuccess, appState, setAppState, isLocalizing, localize])

    // handle events
    useEffect(() => {
        const handleContentOn = (id: string) => {
            setAppState(AppState.ARTWORK_VIEWING);
            console.log('id', id);
            setCurrentArtwork(id)
        }

        const handleContentOff = () => {
            setCurrentArtwork("");
            setAppState(AppState.CALIBRATED);
        }

        const handleStarted = () => {
            setAppState(AppState.CALIBRATING);
        };

        const handleLocalized = (e) => {
            if (e.success) setLocalizedSuccess((prev) => prev + 1)
            setTotalLocalizedCount((prev) => prev + 1)
        }

        emitter.on('localized', handleLocalized)
        emitter.on('xr-started', handleStarted);
        emitter.on('content-on', handleContentOn)
        emitter.on('content-off', handleContentOff)
        return () => {
            emitter.off('content-on', handleContentOn);
            emitter.off('content-off', handleContentOff)
            emitter.off('xr-started', handleStarted);
            emitter.off('localized', handleLocalized)



        }
    }, [setAppState, setCurrentArtwork, appState])

    const showUI = useMemo(() => {
        return appState === 'CALIBRATED' || appState === 'ARTWORK_VIEWING';
    }, [appState])

    useEffect(() => {
        // if (import.meta.env.DEV) return
        if (!userId || !device) return;
        const loadGA = async () => {
            await initAnalytics('G-Z7P4MQLWZR')
            if (device === 'iOS' || device === 'Android' || !isMobile) {
                sendEvent('page_view', {
                    'page_title': document.title,
                    'page_path': window.location.pathname
                });
            }
        }

        loadGA();
    }, [userId, device, initAnalytics, sendEvent, isMobile]);


    return (
        <div className="h-[100dvh] w-full relative z-10
        ">
            <div className={clsx('', {
                'opacity-0 pointer-events-none': appState === 'SPLASH' || appState === 'LOADING',
                'opacity-1 pointer-events-auto': appState !== 'LOADING' && appState !== 'SPLASH'
            })}>
                <Button className="absolute top-[calc(env(safe-area-inset-bottom)+3rem)] left-4 bg-gray-500 bg-opacity-50" onClick={() => window.location.reload()}>Refresh</Button>
                <div className="bg-black absolute w-full h-[80px] bottom-0 bg-opacity-50">
                </div>
                <div className="absolute bottom-[calc(env(safe-area-inset-bottom)+1rem)] right-4 text-md text-shadow"> Localizations: {localizedSuccess}/{totalLocalizedCount}<br></br>Duration: {localizeDuration}s</div>
                <div className="absolute bottom-[calc(env(safe-area-inset-bottom)+1rem)] left-4 text-md">
                    <DebugMenu />
                </div>
            </div>

            {/* <XRCheckOverlay /> */}

            <FadeTransition show={appState === 'SPLASH'}>
                <div className="h-full w-full bg-white flex justify-center items-center">
                    <h2 className="text-black">Coming soon...</h2>
                    {/* <Splash /> */}
                </div>
            </FadeTransition>

            {/* <FadeTransition show={appState === 'CALIBRATING' || appState === 'CALIBRATED'}>
                <div className="w-full h-full ">
                    <InstructionsOverlay />
                </div>
            </FadeTransition>

            <FadeTransition show={(appState === 'ARTWORK_VIEWING') && recordingState !== 'RECORDING'}>
                <div className="absolute top-4 p-4 flex flex-col gap-2 w-full">
                    <div className="line border-b border-white w-full p-4 "></div>
                    <p className="text-center text-2xl font-bold">{currentArtwork?.artist}</p>
                    <p className="text-center text-2xl font-bold">{currentArtwork?.title}</p>
                    <div className="line border-t border-white w-full p-4 "></div>
                </div>
            </FadeTransition>

            <FadeTransition show={showUI}>
                <div className="w-full h-full">
                    <RecordingButton />
                    {recordingState !== 'RECORDING' &&
                        <ProgressOverlay />
                    }
                </div>
            </FadeTransition>

            <FadeTransition show={appState === 'MEDIA_SHARE'}>
                <div className="w-full h-full">
                    <MediaPreview />
                </div>
            </FadeTransition> */}
        </div>
    );
}


render(
    <AppBaseProvider>
        <ArtworkProvider>
            <App />
        </ArtworkProvider>
    </AppBaseProvider>,
    document.getElementById('app')!
);
