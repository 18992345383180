import { IAssetBase } from "@/apps/app.content";
import ModelPath from "@lincolncenter/breakers/breakers.glb";
import VideoPath from "@lincolncenter/breakers/breakers.mp4";
//
import ContentLincolnCenterModelVideo from "./content.lincolncenter.modelvideo";
import { Breakers } from "./content.lincolncenter.types";
import { PBRMaterial } from "babylonjs";

export default class ContentLincolnCenterBreakers extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = Breakers;
    this.modelPath = ModelPath;
    this.videoPath = VideoPath;
    this.videoMaterialName = "BreakersVideo";
    this.checkVideoSynchronization = false;
    this.loopAnimation = false;
  }

  public loadAssetComplete(asset: IAssetBase): void {
    super.loadAssetComplete(asset);

    if (this.model) {
      for (const mesh of this.model.meshes ?? []) {
        if (mesh.material && mesh.material instanceof PBRMaterial) {
          const pbrMaterial = mesh.material as PBRMaterial;
          pbrMaterial.useAlphaFromAlbedoTexture = false;
        }
      }
    }
  }
}
