/* eslint-disable @typescript-eslint/no-unused-vars */

import { ErrorWebXR } from '@/core/errors/error.webxr';
import emitter from '../core/events/eventemitter';
import { LocalizeResult } from '../core/immersal/immersal.common';
import { MediaRecorderResponse } from '@/core/mediarecorder/mediarecorder.types';
import { ShareResponse } from '@/core/share/share.types';

export default class AppBase {

  constructor() {
    emitter.on('load', this.loadEvent.bind(this) );
    emitter.on('xr-start', this.startXREvent.bind(this) );
    emitter.on('xr-stop', this.stopXREvent.bind(this) );
    emitter.on('record-start', this.recordStartEvent.bind(this) );
    emitter.on('record-stop', this.recordStopEvent.bind(this) );
    emitter.on('share', this.shareEvent.bind(this) );
    emitter.on('localize', this.localizeEvent.bind(this) );
  }

  public dispose() {
    emitter.off('load', this.loadEvent.bind(this) );
    emitter.off('xr-start', this.startXREvent.bind(this) );
    emitter.off('xr-stop', this.stopXREvent.bind(this) );
    emitter.off('record-start', this.recordStartEvent.bind(this) );  
    emitter.off('record-stop', this.recordStopEvent.bind(this) );
    emitter.off('share', this.shareEvent.bind(this) );
    emitter.off('localize', this.localizeEvent.bind(this) );
  }

  //---------------------------------------------------------------- 
  public init() {
    this.initialize();
    emitter.emit('initialized');
  }

  protected initialize() {
    // override this method in the derived class.
  }

  //---------------------------------------------------------------- 
  public async loadAsync(): Promise<void> {
    return Promise.reject(new Error("app.base.loadAsync must be overridden in the subclass"));
  }
  
  protected async loadEvent() {
    await this.loadAsync();
    emitter.emit('loaded');
  }

  //---------------------------------------------------------------- 
  public async startXR(): Promise<void> {
    return Promise.reject(new Error("app.base.startXR must be overridden in the subclass"));
  }

  protected async startXREvent() {
    try {
      await this.startXR();
      emitter.emit('xr-started');
    } catch( e ) {
      const error = e as Error;
      if( error.name === ErrorWebXR.UNDEFINED ) {
        emitter.emit('xr-error-undefined');
      } else if( error.name ===  ErrorWebXR.NOT_SUPPPORTED ) {
        emitter.emit('xr-error-not-supported');
      } else if( error.name === ErrorWebXR.PERMISSION_DENIED ) {
        emitter.emit('xr-error-permission-denied');
      }
    }
  }

  //---------------------------------------------------------------- 
  public async stopXR(): Promise<void> {
    return Promise.reject(new Error("app.base.stopXR must be overridden in the subclass"));
  }

  protected async stopXREvent() {
    await this.stopXR();
    emitter.emit('xr-stopped');
  }

  //---------------------------------------------------------------- 
  protected async exitXREvent() {
    emitter.emit('xr-exited');
  }

  //---------------------------------------------------------------- 
  public async recordStartAsync(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("app.base.recordStartAsync must be overridden in the subclass"));
  }

  protected async recordStartEvent() {
    try {
      const result = await this.recordStartAsync();
      emitter.emit('record-started', result);
    } catch( error ) {
      emitter.emit('record-started', error as Error );
    }
  }

  //---------------------------------------------------------------- 
  public async recordStopAsync(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("app.base.recordStopAsync must be overridden in the subclass"));
  }

  protected async recordStopEvent() {
    try {
      const result = await this.recordStopAsync();
      emitter.emit('record-stopped', result);
    } catch( error ) {
      emitter.emit('record-stopped', error as Error );
    }
  }

  //----------------------------------------------------------------
  public async shareAsync(_shareData:ShareData): Promise<ShareResponse> {
    return Promise.reject(new Error("app.base.shareAsync must be overridden in the subclass"));
  }

  protected async shareEvent(shareData:ShareData) {
    try {
      const result = await this.shareAsync(shareData);
      emitter.emit('shared', result);
    } catch( error ) {
      emitter.emit('shared', error as Error );
    }
  }

  //---------------------------------------------------------------- 
  public async localizeAsync(): Promise<LocalizeResult> {
    return Promise.reject(new Error("app.base.localizeAsync must be overridden in the subclass"));
  }

  protected async localizeEvent() {
    try {
      const result = await this.localizeAsync();
      emitter.emit('localized', result);
    } catch( error ) {
      emitter.emit('localized', error as Error );
    }
  }
}