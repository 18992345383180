// src/hooks/useLocalization.ts
import { LocalizeResult } from '@/core/immersal/immersal.common';
import { AppState } from '../types/AppState';
import emitter from '@/core/events/eventemitter';
import debounce from '../utils/debounce';
import { useApp } from './useApp';
import { useEffect, useRef, useState } from 'preact/hooks';

const isLocalizeResult = (event: any): event is LocalizeResult => {
  return (event as LocalizeResult).success !== undefined;
};

const useLocalization = () => {
  const { appState } = useApp();

  const [isLocalizing, setIsLocalizing] = useState(false);
  const [localizeSuccess, setLocalizeSuccess] = useState<boolean | null>(null);
  const [localizeDuration, setLocalizeDuration] = useState<number | null>(null);
  const startTimeRef = useRef<number | null>(null);

  const localize = () => {
    startTimeRef.current = performance.now(); 
    emitter.emit('localize');
    setIsLocalizing(true);
  };

  const handleLocalized = debounce((event: LocalizeResult | Error) => {
    if (startTimeRef.current !== null) {
      const durationInSeconds = parseFloat(((performance.now() - startTimeRef.current) / 1000).toFixed(2));
      setLocalizeDuration(durationInSeconds);
    }
    setIsLocalizing(false);
    if (isLocalizeResult(event)) {
      setLocalizeSuccess(event.success);
    } else if (event instanceof Error) {
      setLocalizeSuccess(false);
    }
    if (appState === AppState.CALIBRATING || appState === AppState.CALIBRATED || appState === AppState.ARTWORK_VIEWING) {
      localize();
    }
  }, 1000);

  useEffect(() => {
    emitter.on('localized', handleLocalized);
    return () => {
      emitter.off('localized', handleLocalized);
    };
  }, [handleLocalized]);

  return { isLocalizing, localizeSuccess, localize, localizeDuration };
};

export default useLocalization;
