
import mitt, { Emitter } from 'mitt';
import { Events } from './Events';

class EventEmitter {
  private static instance: EventEmitter;
  private emitter: Emitter<Events>;

  private constructor() {
    this.emitter = mitt<Events>();
  }

  public static getInstance(): EventEmitter {
    if (!EventEmitter.instance) {
      EventEmitter.instance = new EventEmitter();
    }
    return EventEmitter.instance;
  }

  public on<Key extends keyof Events>(type: Key, handler: (event: Events[Key]) => void): void {
    this.emitter.on(type, handler);
  }

  public off<Key extends keyof Events>(type: Key, handler: (event: Events[Key]) => void): void {
    this.emitter.off(type, handler);
  }

  public emit<Key extends keyof Events>(type: Key, event?: Events[Key]): void {
    this.emitter.emit(type, event);
  }
}

export default EventEmitter.getInstance();