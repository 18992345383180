import {
  Camera,
  Engine,
  Scene,
  Texture,
} from "babylonjs";
import ContentBase from "./content.base";
import ContentUIImage from "./content.ui.image";

export default class ContentUI extends ContentBase {
  protected _screenWidthCached = 0;
  protected _screenHeightCached = 0;

  protected images: ContentUIImage[] = [];

  constructor() {
    super();
  }

  public override init(engine: Engine, scene: Scene, camera: Camera): void {
    super.init(engine, scene, camera);
    this.cacheScreenDimensions();
  }

  public createImage(texture: Texture): ContentUIImage {
    const image = new ContentUIImage(texture, this.scene!);
    this.images.push(image);
    return image;
  }

  public override render(): void {
    super.render();
    if (this.images) {
      this.images.forEach((image) => {
        image.update(this.screenWidth, this.screenHeight);
      });
    }
  }

  public override resize(): void {
    this.cacheScreenDimensions();
  }

  public cacheScreenDimensions() {
    this._screenWidthCached = this.engine!.getRenderWidth();
    this._screenHeightCached = this.engine!.getRenderHeight();
  }

  public get screenWidth(): number {
    return this._screenWidthCached;
  } 

  public get screenHeight(): number {
    return this._screenHeightCached;
  }
}