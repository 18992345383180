import LocalStorageBase from "./localstorage.base";

export default class LocalStorageWeb extends LocalStorageBase {
  
  constructor() {
    super();
  }

  public override async setItemAsync(key: string, value: string): Promise<void> {
    return Promise.resolve(localStorage.setItem(key, value));
  }

  public override async getItemAsync(key: string): Promise<string | null> {
    return Promise.resolve(localStorage.getItem(key));
  }

  public override async removeItemAsync(key: string): Promise<void> {
    return Promise.resolve(localStorage.removeItem(key));
  }

  public override async clearAsync(): Promise<void> {
    return Promise.resolve(localStorage.clear());
  }
}