import {
  Camera,
  Engine,
  Scene,
  Texture,
  Vector3,
} from "babylonjs";
import ContentUIImage from "../core/content.ui.image";
import ContentUIArrow from "../core/content.ui.arrow";

export default class ContentLincolnCenterArrow extends ContentUIArrow {
  
  protected arrow?: ContentUIImage;
  protected arrowText?: ContentUIImage;

  constructor() {
    super();
  }

  public override init(engine: Engine, scene: Scene, camera: Camera): void {
    super.init(engine, scene, camera);
  }

  public setArrowTexture(texture: Texture) {
    this.arrow = this.createImage(texture);
    this.arrow.alpha = 0;
  }

  public setArrowTextTexture(texture: Texture) {
    this.arrowText = this.createImage(texture);
    this.arrowText.alpha = 0;
  }

  public updateArrowTextTexture(texture: Texture) {
    if( this.arrow ) {
      this.arrowText?.switchTexture(texture);
    }
  }

  protected setArrowScale() {
    if( !this.arrow ) {
      return;
    }
    const targetSize = this.screenHeight * 0.1;
    const maxDimension = Math.max( this.arrow.textureWidth, this.arrow.textureHeight );
    const scale =  targetSize / maxDimension;
    this.arrow.scaleX = scale;
    this.arrow.scaleY = scale;
  }

  protected setArrowTestScale() {
    if( !this.arrowText ) {
      return;
    }
    const targetSize = this.screenHeight * 0.2;
    const maxDimension = Math.max( this.arrowText.textureWidth, this.arrowText.textureHeight );
    const scale =  targetSize / maxDimension;
    this.arrowText.scaleX = scale;
    this.arrowText.scaleY = scale;
  }

  public override pointTo(position3D: Vector3): void {
    super.pointTo(position3D);
    if (!this.arrow || !this.arrowText) {
      return;
    }
    this.arrow.x = this.arrowPositionEased.x;
    this.arrow.y = this.arrowPositionEased.y;
    this.arrow.rotation = this.arrowRotation;
    this.arrow.alpha = this.arrowAlphaEased;
    //
    const arrowRadius = Math.max( this.arrow.width, this.arrow.height ) * 0.5;
    const arrowTextRadiusW = this.arrowText.width * 0.5;
    const arrowTextRadiusH = this.arrowText.height * 0.5;
    const arrowTextRadius = Math.sqrt(
      ((arrowTextRadiusW ** 2) * (this.arrowDirection.x ** 2)) +
      ((arrowTextRadiusH ** 2) * (this.arrowDirection.y ** 2))
    );
    const arrowTextDistance = arrowTextRadius + arrowRadius;
    const arrowTextDistanceWithPadding = arrowTextDistance * 1.1;
    this.arrowText.x = this.arrow.x - this.arrowDirection.x * arrowTextDistanceWithPadding;
    this.arrowText.y = this.arrow.y - this.arrowDirection.y * arrowTextDistanceWithPadding;
    this.arrowText.rotation = Math.PI;
    this.arrowText.alpha = this.arrow.alpha;
  }

  public hideArrow() {
    this.arrowShowOverride = false;
    this.arrow!.alpha = 0;
    this.arrowText!.alpha = 0;
  }

  public override render(): void {
    this.cacheScreenDimensions();
    this.arrowScreenPadding = 0.05 * this.screenHeight;
    this.setArrowScale();
    this.setArrowTestScale();
    super.render();
  }
}