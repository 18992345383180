import ImmersalBase from "./immersal.base";
import { CameraIntrinsics, LocalizeResult, RequestLocalizeImage, ResponseLocalizeImage } from "./immersal.common";
import { Matrix } from "babylonjs"; // eventually remove dependency on BabylonJS

export default class ImmersalWebCloud extends ImmersalBase {
  
  private pngEncoderWorker!: Worker;
  
  constructor(token: string, mapIds: number[]) {
    super(token, mapIds);
  }

  public override async init(): Promise<void> {
    this.pngEncoderWorker = new Worker("js/pngEncoderWorker.js");
    return Promise.resolve();
  }

  public override async loadMap(): Promise<void> {
    console.warn("immersal.web.cloud.loadMap not required.");
    return Promise.resolve();
  }

  private encodeImageInWorker(imageData: Uint8ClampedArray, imageWidth: number, imageHeight: number): Promise<ArrayBuffer> {
    return new Promise((resolve) => {
      this.pngEncoderWorker.onmessage = (event) => {
        resolve(event.data as ArrayBuffer);
      };
      this.pngEncoderWorker.postMessage({ imageData, imageWidth, imageHeight });
    });
  }  

  public override async localize(imageData?: Uint8ClampedArray, imageWidth?: number, imageHeight?: number, intrinsics?: CameraIntrinsics): Promise<LocalizeResult> {
    if (!imageData || !imageWidth || !imageHeight || !intrinsics) {
      throw new Error('immersal.web.cloud.localize - imageData, imageWidth, imageHeight and intrinsics are required.');
    }
    try {
      const mapIds = this.mapIds.map(mapId => ({ id: parseInt(mapId.toString()) }));
      const req: RequestLocalizeImage = {
        mapIds: mapIds,
        fx: intrinsics!.focalLength.x,
        fy: intrinsics!.focalLength.y,
        ox: intrinsics!.principalOffset.x,
        oy: intrinsics!.principalOffset.y,
        param1: 0, // copy from three.js
        param2: 12, // copy from three.js
        param3: 0.0, // copy from three.js
        param4: 2.0, // copy from three.js
        token: this.token,
      };
      const buffer = await this.encodeImageInWorker(imageData, imageWidth, imageHeight);
      const payload = new Blob([JSON.stringify(req), '\0', buffer]);
      const response = await fetch('https://api.immersal.com/localize', {
        method: "POST",
        body: payload,
      });
      if (!response.ok) {
        throw new Error('immersal.web.cloud.localize - network response was not ok');
      }
      const res: ResponseLocalizeImage = await response.json();
      if (!res) {
        throw new Error('immersal.web.cloud.localize: no result.');
      }
      const locResult: LocalizeResult = {
        success: false,
        confidence: -1,
      };
      if( res.success ) {
        let matrix = new Matrix(); // three.js version.
        matrix.setRowFromFloats(0, res.r00, -res.r01, -res.r02, res.px);
        matrix.setRowFromFloats(1, res.r10, -res.r11, -res.r12, res.py);
        matrix.setRowFromFloats(2, res.r20, -res.r21, -res.r22, res.pz);
        matrix.setRowFromFloats(3, 0, 0, 0, 1);
        matrix = matrix.transpose();
  
        locResult.success = true;
        locResult.matrix = new Float32Array(16);
        matrix.toArray(locResult.matrix, 0);
        locResult.confidence = res.confidence ?? locResult.confidence;
        locResult.map = res.map;
      }
      return locResult;
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`${error.message || error}`);
      } else {
        throw new Error(String(error));
      }
    }
  }
}