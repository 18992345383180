import Utils from "../utils/utils";
import Bridge, { BridgeMessage } from "./bridge";
import { 
  BridgeMessageTypeLocalStorageSetItem,
  BridgeMessageTypeLocalStorageGetItem, 
  BridgeMessageTypeLocalStorageRemoveItem, 
  BridgeMessageTypeLocalStorageClear, 
} from "./bridge.types";

export default class BridgeLocalStorage extends Bridge {
  constructor() {
    super();
  }
    
  public async setItemAsync(key: string, value: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeLocalStorageSetItem,
        data: { "key": key, "value": value },
      };
      this.send(message, resolve, reject);
    });
  }

  public async getItemAsync(key: string): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeLocalStorageGetItem,
        data: { "key": key },
      };
      this.send(message, resolve, reject);
    });
  }

  public async removeItemAsync(key: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeLocalStorageRemoveItem,
        data: { "key": key },
      };
      this.send(message, resolve, reject);
    });
  }

  public async clearAsync(): Promise<void> {
    return new Promise((resolve, reject) => {
      const message: BridgeMessage = {
        uuid: Utils.UUID(),
        type: BridgeMessageTypeLocalStorageClear,
        data: undefined,
      };
      this.send(message, resolve, reject);
    });
  }
}