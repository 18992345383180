import BridgeShare from "../bridge/bridge.share";
import ShareBase from "./share.base";
import { ShareResponse } from "./share.types";

export default class ShareApp extends ShareBase {
  
  private bridge: BridgeShare;
  
  constructor() {
    super();
    this.bridge = new BridgeShare();
  }

  public override async share( data:ShareData ): Promise<ShareResponse> {
    return this.bridge.share( data ) as unknown as ShareResponse;
  }
}