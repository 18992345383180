import { MediaRecorderResponse } from "./mediarecorder.types";

export default class MediaRecorderBase {
  constructor() {
    //
  }

  public async start(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("mediarecorder.base.start must be overridden in the subclass"));
  }

  public async stop(): Promise<MediaRecorderResponse> {
    return Promise.reject(new Error("mediarecorder.base.stop must be overridden in the subclass"));
  }
}