import ContentLincolnCenterModelVideo from "./content.lincolncenter.modelvideo";
import { Ballet } from "./content.lincolncenter.types";
import Model from "@lincolncenter/ballet/ballet.glb";
import Video from "@lincolncenter/ballet/ballet.mp4";

export class ContentLincolnCenterBallet extends ContentLincolnCenterModelVideo {
  constructor() {
    super();
    this.id = Ballet;
    this.checkVideoSynchronization = false;

    this.modelPath = Model;
    this.videoPath = Video;
    this.videoMaterialName = "VideoTexture";

  }
}