export default function debounce(
    callback: (...args: any) => void,
    wait: number,
    thresholdCallback?: () => void
  ) {
    let timeout: ReturnType<typeof setTimeout> | undefined = undefined;
    let start: number;
    return (...args) => {
      const later = () => {
        const end = Date.now();
        const executionTime = end - start;
  
        if (executionTime > wait) {
          if (thresholdCallback) thresholdCallback();
        }
        callback(...args);
      };
      clearTimeout(timeout);
      start = Date.now();
      timeout = setTimeout(later, wait);
    };
  }
  