import {
  Camera,
  Engine,
  Scene,
  Vector2,
  Vector3,
} from "babylonjs";
import { getCameraViewMatrix, projectToScreenSpace } from "./content.common";
import ContentUI from "./content.ui";

export default class ContentUIArrow extends ContentUI {
  protected arrowScreenPadding: number = 0;
  protected arrowPosition: Vector2 = new Vector2(0, 0);
  protected arrowPositionEased: Vector2 = new Vector2(0, 0);
  protected arrrowPositionEasing: number = 0.2;
  protected arrowRotation: number = 0;
  protected arrowDirection: Vector2 = new Vector2(0, 0);
  protected arrowAlpha: number = 0;
  protected arrowAlphaEased: number = 0;
  protected arrowAlphaEasing: number = 0.2;
  protected arrowShow: boolean = false;
  public arrowShowOverride: boolean = false;

  constructor() {
    super();
  }

  public override init(engine: Engine, scene: Scene, camera: Camera): void {
    super.init(engine, scene, camera);
  }

  public pointTo(position3D: Vector3): void {
    let behindCamera = false; // default.
    let screenPos = new Vector2(this.screenWidth * 0.5, this.screenHeight * 0.5); // default center.
    const camera = this.scene!.activeCamera;
    if( camera ) {
      screenPos = projectToScreenSpace(this.engine!, camera, position3D);
      behindCamera = this.isBehindCamera(camera, position3D);
      if (behindCamera) {
        screenPos.x = this.screenWidth - screenPos.x;
        screenPos.y = this.screenHeight - screenPos.y;
      }
    }
    const offscreen = this.isOffScreen(screenPos);
    if (offscreen || behindCamera) {
      const arrowData = this.getArrowPositionAndRotation(screenPos, this.arrowScreenPadding);
      this.arrowPosition.set(arrowData.x, arrowData.y);
      this.arrowRotation = arrowData.rotation;
      this.arrowDirection.x = Math.cos(this.arrowRotation);
      this.arrowDirection.y = Math.sin(this.arrowRotation);
      this.arrowAlpha = 1;
      if (!this.arrowShow) {
        this.arrowPositionEased.x = this.arrowPosition.x;
        this.arrowPositionEased.y = this.arrowPosition.y;
        this.arrowShow = true;
      }
    } else {
      if (this.arrowShow) {
        this.arrowShow = false;
        this.arrowAlpha = 0;
      }
    }
    if( !this.arrowShowOverride ) {
      this.arrowAlpha = 0;
    }
    this.arrowPositionEased.x += (this.arrowPosition.x - this.arrowPositionEased.x) * this.arrrowPositionEasing;
    this.arrowPositionEased.y += (this.arrowPosition.y - this.arrowPositionEased.y) * this.arrrowPositionEasing;
    this.arrowAlphaEased += (this.arrowAlpha - this.arrowAlphaEased) * this.arrowAlphaEasing;
  }

  protected isOffScreen(screenPos: Vector2) {
    return (
      screenPos.x < 0 ||
      screenPos.x > this.screenWidth ||
      screenPos.y < 0 ||
      screenPos.y > this.screenHeight
    );
  }

  protected clampToScreenEdges(x: number, y: number): Vector2 {
    const clampedX = Math.min(Math.max(x, 0), this.screenWidth);
    const clampedY = Math.min(Math.max(y, 0), this.screenHeight);
    return new Vector2(clampedX, clampedY);
  }

  protected isBehindCamera(camera: Camera, objectPosition: Vector3): boolean {
    const worldMatrix = getCameraViewMatrix(camera).invert();
    const cameraForward = camera.getForwardRay(100, worldMatrix).direction;
    const cameraToObject = objectPosition.subtract(camera.position).normalize();
    const dotProduct = Vector3.Dot(cameraForward, cameraToObject);
    return dotProduct < 0;
  }

  protected getArrowPositionAndRotation(
    screenPos: Vector2,
    padding: number
  ): { x: number; y: number; rotation: number } {
    const centerX = this.screenWidth / 2;
    const centerY = this.screenHeight / 2;
    const dirX = screenPos.x - centerX;
    const dirY = screenPos.y - centerY;
    const angle = Math.atan2(dirY, dirX);
    const length = Math.sqrt(dirX * dirX + dirY * dirY);
    const dirXN = dirX / length;
    const dirYN = dirY / length;
    const minX = padding;
    const maxX = this.screenWidth - padding;
    const minY = padding;
    const maxY = this.screenHeight - padding;
    const intersection = this.getIntersectionWithRect(
      centerX,
      centerY,
      dirXN,
      dirYN,
      minX,
      minY,
      maxX,
      maxY
    );
    return {
      x: intersection.x,
      y: intersection.y,
      rotation: angle,
    };
  }

  protected getIntersectionWithRect(
    centerX: number,
    centerY: number,
    dirX: number,
    dirY: number,
    minX: number,
    minY: number,
    maxX: number,
    maxY: number
  ): { x: number; y: number } {
    const tValues: number[] = [];
    if (dirX !== 0) {
      // Right edge
      let t = (maxX - centerX) / dirX;
      let y = centerY + t * dirY;
      if (y >= minY && y <= maxY && t > 0) tValues.push(t);
      // Left edge
      t = (minX - centerX) / dirX;
      y = centerY + t * dirY;
      if (y >= minY && y <= maxY && t > 0) tValues.push(t);
    }
    if (dirY !== 0) {
      // Bottom edge
      let t = (maxY - centerY) / dirY;
      let x = centerX + t * dirX;
      if (x >= minX && x <= maxX && t > 0) tValues.push(t);
      // Top edge
      t = (minY - centerY) / dirY;
      x = centerX + t * dirX;
      if (x >= minX && x <= maxX && t > 0) tValues.push(t);
    }
    if (tValues.length === 0) {
      return { x: centerX, y: centerY };
    }
    const tMin = Math.min(...tValues);
    return {
      x: centerX + dirX * tMin,
      y: centerY + dirY * tMin,
    };
  }
}
