// qrcode
export const BridgeMessageTypeQRCodeScan = "qrcode/scan";
// immersal
export const BridgeMessageTypeImmersalLoadMap = "immersal/loadmap";
export const BridgeMessageTypeImmersalLocalizeOnDevice = "immersal/localizeondevice";
export const BridgeMessageTypeImmersalLocalizeOnCloud = "immersal/localizeoncloud";
// mediarecorder
export const BridgeMessageTypeMediaRecorderStart = "mediarecorder/start";
export const BridgeMessageTypeMediaRecorderStop = "mediarecorder/stop";
// localstorage
export const BridgeMessageTypeLocalStorageSetItem = "localstorage/setitem";
export const BridgeMessageTypeLocalStorageGetItem = "localstorage/getitem";
export const BridgeMessageTypeLocalStorageRemoveItem = "localstorage/removeitem";
export const BridgeMessageTypeLocalStorageClear = "localstorage/clear";
// localstorage
export const BridgeMessageTypeShare = "share";
// geolocation
export const BridgeMessageTypeGeolocationStart = "geolocation/start";
export const BridgeMessageTypeGeolocationStop = "geolocation/stop";